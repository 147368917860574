import { Pagination, Table } from 'antd'
import { useTranslation } from 'react-i18next';

function CounterTable({ data, columns, meta, onPage }: any) {
    const { t } = useTranslation();

    return (
        <div className='sales-table'>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
                <Pagination
                    total={meta?.totalCount}
                    showSizeChanger={true}
                    showTotal={(total) => `${(t as any)("counter.text_5")} ${meta?.totalCount} ${(t as any)("counter.text")}`}
                    onChange={(page, pageSize) => onPage(page, pageSize)}
                />
            </div>
        </div>
    )
}

export default CounterTable