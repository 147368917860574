import { Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET } from '../../../utils/apiCalls';
import '../styles.scss';
import ClosePayment from './closePayment';
import { useTranslation } from 'react-i18next';
function SaleTable({ columns, list, relode }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<any[]>([]);
    const [bankList, setBankList] = useState<any>()
    const [closePaymentModal, setClosePaymentModal] = useState(false);

    const fetchBankList = async () => {
        try {
            let bank_list_url = "account_master/getBankList/" + user?.id + '/' + user?.staff?.companyid;
            const { data }: any = await GET(bank_list_url, null);
            setBankList(data?.list);
        } catch (error) {
            console.error(error);
        }
    };

    const onSelectChange = (selectedRowKeys: any, selectedRowData: any) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRowData(selectedRowData)
    };

    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        getCheckboxProps: (record: any) => ({
            disabled: record.type == 'Supplier Payment' ? true : record.status == 'open' ? false : true
        })
    };


    let modifiedList = list.map((item: any, index: number) => {
        return {
            key: index,
            ...item
        }
    });

    useEffect(() => {
        fetchBankList()
    }, [])
    return (
        <div className='sales-table'>
            <Table
                columns={columns}
                dataSource={modifiedList}
                pagination={false}
                rowSelection={rowSelection}
            />
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button disabled={selectedRowData.length ? false : true} size='large' type='primary' onClick={() => setClosePaymentModal(true)}>{(t as any)("invoice.text_11")}</Button>
                <div className='table-font'>{(t as any)("invoice.text_10")}</div>
            </div>
            {
                closePaymentModal &&
                <ClosePayment
                    isOpen={closePaymentModal}
                    setModalOpen={() => setClosePaymentModal(false)}
                    selectedRow={selectedRowData}
                    bankList={bankList}
                    refresh={relode}
                />
            }
        </div>
    );
};

export default SaleTable;