import { createSlice } from "@reduxjs/toolkit";
function updateObject(obj1: any, obj2: any) {
  return { ...obj1, ...obj2 };
}

const companyupdate = (user: any, data: any) => {
  const updatedObject = { ...user };
  updatedObject.companyInfo = data;
  return updatedObject;
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    baseUrl: "",
    user: {},
    token: null,
    auth: false,
    mailWerify: false,
    signUpDetails: {
      firstname: "",
      lastname: "",
      password: "",
      country_code: "",
      bcategory: "",
      bname: "",
      countryid: "",
      remember: "",
      email: "",
      phonenumber: "",
      mobileverified: 1,
      status: 1,
      seletedCountry: {},
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action?.payload?.data;
      state.auth = action?.payload?.auth;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setBaseUrl: (state, action) => {
      state.baseUrl = action.payload;
    },
    setMailWerify: (state) => {
      state.mailWerify = true;
    },
    update: (state, action) => {
      state.user = action.payload;
    },
    companyUpdate: (state, action) => {
      state.user = companyupdate(state.user, action.payload);
    },
    logout: (state, action) => {
      state.user = {};
      state.auth = false;
      state.token = null;
      state.mailWerify = false;
    },
    addSignUpDetails: (state, action) => {
      state.signUpDetails = updateObject(state.signUpDetails, action.payload);
    },
  },
});

export const {
  login,
  setToken,
  logout,
  update,
  addSignUpDetails,
  setBaseUrl,
  setMailWerify,
  companyUpdate,
} = AuthSlice.actions;
export default AuthSlice.reducer;
