import { Button, Form, Input, Popover, Space, Tooltip } from "antd";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { addImeis } from "../../../redux/slices/retailExpress";
import { useDispatch } from "react-redux";

function ListItem(props: any) {
  const dispatch = useDispatch();
  const [count, setCount] = useState(Math.max(props.item.quantity_no, 1));
  const [popoverVisible, setPopoverVisible] = useState(false);
  const data = (values: any) => {
    const obj: any = { id: props.item.id, data: values };
    dispatch(addImeis(obj));
    setPopoverVisible(false);
  };
  const handleCancel = () => {
    setPopoverVisible(false);
  };
  const content = (
    <div
      className="h-auto d-flex flex-column overflow-y-auto"
      style={{
        maxHeight: 270,
      }}
    >
      <Form onFinish={data}>
        {[...Array(count)].map((_, index) => (
          <Form.Item key={index} name={`number${index + 1}`}>
            <Input size="small" placeholder={`Number ${index + 1}`} />
          </Form.Item>
        ))}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button htmlType="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
  return (
    <div className="RetailExpress-ListItem">
      <div className="RetailExpress-ListItemtxt">
        {Number(props?.index) + 1}.
      </div>
      <div style={{ flex: 1 }}>
        <Tooltip title="Click to add IMEI number">
          <Space wrap>
            <Popover
              content={content}
              title="Add IMEI"
              trigger="click"
              visible={popoverVisible}
              onVisibleChange={(visible) => setPopoverVisible(visible)}
            >
              <div style={{ cursor: "pointer" }}>
                <div className="RetailExpress-ListItemtxt1">
                  {props?.item?.idescription}
                </div>
                <div className="RetailExpress-ListItemtxt2">
                  {`${props?.currencycode ? props?.currencycode?.symbol : ""} ${
                    props?.item?.rate
                  }`}{" "}
                  | {props?.item?.quantity_no} {props?.item?.unitDetails?.unit}
                </div>
              </div>
            </Popover>
          </Space>
        </Tooltip>
      </div>
      <div>
        <Space.Compact block>
          <Button
            size="small"
            onClick={() => {
              if (count > 1) {
                props.minus(props.item.id);
                setCount(count - 1);
              }
            }}
          >
            -
          </Button>
          <input
            style={{
              border: "1px solid #d3d3d3",
              outline: "none",
              textAlign: "center",
              width: "30px",
            }}
            onInput={(e: any) => {
              e.currentTarget.value = e.currentTarget.value.replace(
                /[^0-9]/g,
                ""
              );
            }}
            value={count}
            onChange={(e) => {
              const value = Math.max(Number(e.target.value), 1);
              if (!isNaN(value)) {
                setCount(value);
                props.enter({
                  id: props.item.id,
                  quantity: value,
                });
              }
            }}
          />
          <Button
            size="small"
            onClick={() => {
              props.add(props.item.id);
              setCount(count + 1);
            }}
            style={{ borderLeft: "none" }}
          >
            +
          </Button>
        </Space.Compact>
      </div>
      &nbsp; &nbsp; &nbsp;
      <div>
        <Button
          size="small"
          danger
          onClick={() => props.isChange(props.item.id)}
        >
          <IoMdClose />
        </Button>
      </div>
    </div>
  );
}

export default ListItem;
