import { Button, Card, Radio, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCircle, FaRegCircle } from "react-icons/fa";
import { useSelector,useDispatch } from "react-redux";
import retailImage from "../../assets/images/retail.png";

import soleTraderImage from "../../assets/images/soltride.png";
import PaymentScreenModal from "../../component/payment";
import Header from "../../component/retailHeader/header";
import API from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import "./styles.scss";
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader";
import { useTranslation } from "react-i18next";
import { storePaystackData } from "../../redux/slices/paystackSlice";

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);

 

  const [pricingDetails, setPricingDetails] = useState<any>();
  const [period, setPeriod] = useState(1);

  const [isRetail, setIsRetail] = useState(false);
  const [isSoleTrader, setIsSoleTrader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [planDetails, setPlanDetails] = useState<any>();
  const [company, setCompany] = useState(1);
  const [counter, setCounter] = useState(1);
  const [affiliationCode, setAffiliationCode] = useState(
    user?.isAffiliateCodeUsed ? "" : user?.affiliationCode
  );
  let currency = user?.countryInfo?.currencycode;


  const addPayment = async () => {
    try {
      setIsLoading(true)
      const res: any = await POST(API.PAYMENT_CREATE, {
        amount: Number(pricingDetails?.totalPrice.toFixed(2)) * 100,
        currency: user?.companyInfo?.countryInfo?.currencycode,
        adminid: user?.id,
      });
      setClientSecret(res?.client_secret);
      if (res?.statusCode !== 503) {
        setOpenModal(true);
        setIsLoading(false)
      } else {
        notification.warning({
          message: "Warning",
          description: res.message,
        });
      }
    } catch (err) {
      console.error("err", err);
    }
  };

  const fetchSbscriptionDetails = async () => {
    try {
      setIsLoading(true);
      let url = API.USER_SUBSCRIBED_PLAN;
      const response: any = await GET(url, null);
      if (response?.status) {
        setPlanDetails(response?.data);
        setIsRetail(response?.data?.retailXpressWithTaxgo)
        setIsSoleTrader(response?.data?.soleTrader)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const card = [
    {
      id: 1,
      head: <div>{(t as any)("subscription.text_26")}</div>,
      image: retailImage,
      para: (t as any)("subscription.text_27"),
    },
    {
      id: 2,
      head: <div>{(t as any)("subscription.text_28")}</div>,
      image: soleTraderImage,
      para: (t as any)("subscription.text_29"),
    },
  ];

  const handleChange = async () => {
    try {
      let url =
        API.GET_PRICING +
        `?company=${0}&counter=${0}&retailXpressWithTaxgo=${isRetail}&soleTrader=${isSoleTrader}&period=${period}&currencyCode=${user?.companyInfo?.countryInfo?.currencycode}`;
      const response: any = await GET(url, null);
      if (response?.status) {
        setPricingDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initializePayment = async () => {
    try {
      const totalPrice = Number(pricingDetails?.totalPrice)?.toFixed(2);
      const obj = {
        adminid: user?.id,
        total: pricingDetails?.totalPrice,
        company: company,
        currency: currency,
        counter: counter,
        soleTrader: isSoleTrader,
        retailXpress: isRetail,
        period: period,
        affiliationCode,
      };
      dispatch(storePaystackData(obj));
      const res: any = await POST(API.PAYSTACK_PAYMENT, {
        amount: totalPrice,
        email: user?.email,
        callback_url: API.WEBURL + "paystack", 
      });

      console.log('David 1 --->',res)


      if (res?.status) {
        const authorizationUrl = res?.data?.authorization_url;
        if (authorizationUrl) {
          // Open the Paystack payment page
          window.location.href = authorizationUrl;
        }
      }
    } catch (error) {
      console.log("--------- error --", error);
      console.error("Error initializing payment:", error);
    }
  };
  useEffect(() => {
    handleChange();
  }, [period, isRetail, isSoleTrader]);

  useEffect(() => {
    fetchSbscriptionDetails();
  }, []);

  return (
    <div className="subscription-mainContainer">
      <div className='d-block d-lg-none'>
        <Header isNotSerach={true} />
      </div>
      <br />
      <Container>
        <OuterPageHeader title={(t as any)("subscription.text")} />
        <br />
        <Card>
          <Row className="g-0">
            <Col className="d-none d-lg-block" lg={1}></Col>
            <Col className="g-0" lg={5} style={{ padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <div>
                <div className="subscription-text">{(t as any)("subscription.text_17")}</div>
                <div className="subscription-text2">{(t as any)("subscription.text_18")}</div>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <Radio.Group
                  value={period}
                  style={{ width: '100%', marginTop: 5 }}
                  buttonStyle="solid"
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <Radio.Button value={1} style={{ width: "33%", height: 30 }}>1 {(t as any)("subscription.text_30")}</Radio.Button>
                  <Radio.Button value={12} style={{ width: "33%", height: 30 }}>12 {(t as any)("subscription.text_30")}</Radio.Button>
                  <Radio.Button value={24} style={{ width: "34%", height: 30 }}>24 {(t as any)("subscription.text_30")}</Radio.Button>
                </Radio.Group>

                <div className="subscription-text3" style={{ marginTop: '20px' }}>{(t as any)("subscription.text_20")}</div>
              </div>

              <div style={{ marginBottom: '25px' }}>
                <div className="subscriptionScreen-companyContainer"
                  style={{ backgroundColor: '#E5F3FF' }}
                  onClick={() => {
                    setIsSoleTrader(!isSoleTrader)
                    setIsRetail(false)
                  }}>
                  {!planDetails?.retailXpressWithTaxgo ? (
                    <div className="textCompanyText">
                      <div className="checkboxContainer">
                        <span className="subscription-text3" style={{ color: '#2B5985' }} >{(t as any)("subscription.text_9")}</span>
                        {!isSoleTrader ? <FaRegCircle color="#2B5985" size={20} /> : <FaCircle size={20} color="#2B5985" />}
                      </div>
                      <div className="formLabel" style={{ marginTop: 4, color: '#2B5985' }}>
                      {(t as any)("subscription.text_10")}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="subscriptionScreen-companyContainer"
                  style={{ backgroundColor: '#FFF2DB' }}
                  onClick={() => {
                    setIsRetail(!isRetail)
                    setIsSoleTrader(false)
                  }}>
                  <div className="textCompanyText">
                    <div className="checkboxContainer">
                      <span className="subscription-text3" style={{ color: '#85642B' }} >{(t as any)("subscription.text_2")}</span>
                      {!isRetail ? <FaRegCircle size={20} color="#85642B" /> : <FaCircle size={20} color="#85642B" />}
                    </div>
                    <div className="formLabel" style={{ marginTop: 4, color: '#85642B' }}>
                    {(t as any)("subscription.text_21")}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <div className="subscriptionScreen-totalAmountContainer">
                  <div className="totalAmountText">
                  {(t as any)("subscription.text_23")} <br />
                  {(t as any)("subscription.text_24")} {user?.companyInfo?.countryInfo?.currencycode}
                  </div>
                  <div className="totalAmountContainer">
                    <span>{user?.companyInfo?.countryInfo?.symbol} {pricingDetails?.totalPrice.toFixed(2)}</span>
                  </div>
                </div>

                <Button
                  type="primary"
                  style={{ width: "100%", height: 45, marginTop: 5 }}
                  onClick={() => {
                   
               
                    if (user?.companyInfo?.countryInfo?.currencycode === "NGN") {
                      initializePayment();
                    } else {
                      addPayment();
                    }
                    
                  }}
                  loading={isLoading}
                >
                  {(t as any)("subscription.text_25")} 
             
               
                </Button>
              </div>
            </Col>
            <Col className="d-none d-lg-block" lg={6} style={{ padding: 40 }}>
              <Row>
                {card?.map((item: any) => (
                  <div className="background-card">
                    <div className="background-div"
                      style={{
                        background: `linear-gradient(to bottom, #ffffff00 60%, #00000099 100%), url(${item?.image}), #ffffff`,
                      }}
                    >
                      <div className="head-text">{item?.head}</div>
                      <div className="text">{item?.para}</div>
                    </div>
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
      {openModal && (
        <PaymentScreenModal
          totalPrice={pricingDetails?.totalPrice}
          data={{
            adminid: user?.id,
            total: pricingDetails?.totalPrice,
            company: 1,
            counter: 1,
            soleTrader: isSoleTrader,
            retailXpress: isRetail,
            period: period,
          }}
          openModal={openModal}
          clientSecret={clientSecret}
          setOpenModal={() => setOpenModal(false)}
        />
      )}
      <br />
      <br />
    </div>
  );
};

export default SubscriptionPage;
