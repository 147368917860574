import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa'

function ClockInModel({ open, onCancel, text }: any) {
    const { t } = useTranslation();

    return (
        <Modal
            title={
                <div
                    className="dashboard-info-modal"
                    style={{ fontSize: "20px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '15px' }}
                >
                    <span>{(t as any)("common.text_3")}</span>
                    <FaExclamationCircle size={24} color="yellow" />
                </div>
            }
            open={open}
            onCancel={() => onCancel()}
            width={500}
            footer={false}
        >
            <div>{text}</div>
        </Modal>
    )
}

export default ClockInModel