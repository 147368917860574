import {
  Button,
  Card,
  Checkbox,
  Input,
  Popover,
  Space,
  Tooltip,
  notification,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { BsDownload, BsMailboxFlag, BsPrinter } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OuterPageHeader from "../../../component/OuterHeader/OuterPageHeader";
import LoadingBox from "../../../component/loadingBox";
import PrintModal from "../../../component/printModal/printModal";
import Header from "../../../component/retailHeader/header";
import SendMailModal from "../../../component/sendMailModal";
import { GET } from "../../../utils/apiCalls";
import "../styles.scss";
import { template1 } from "./templates";
import { useTranslation } from "react-i18next";
import { SlEye } from "react-icons/sl";

function SaleInvoiceView(props: any) {
  const { t } = useTranslation();
  const { id }: any = useParams();
  const User = useSelector((state: any) => state.User);

  const [isFullLoading, setIsFullLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [rowData, setrowData] = useState<any>({});
  const [emailModal, setEmailModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [template, setTemplate] = useState();
  const [tooltipVisible, setTooltipVisible] = useState<Record<number, boolean>>(
    {}
  );

  const handleIconLeave = (index: number) => {
    setTooltipVisible((prev) => ({ ...prev, [index]: false }));
  };

  const handleMouseEnter = (index: number) => {
    setTooltipVisible((prev) => ({ ...prev, [index]: true }));
  };

  let _subTotal = 0;
  let _tatalVat = 0;
  let _overollDiscount = 0;
  let discountAmount: any = 0;
  let amountAdded = 0;
  useEffect(() => {
    getInvoiceDetails();
  }, []);

  const getInvoiceDetails = async () => {
    setIsFullLoading(true);
    try {
      let url = `SaleInvoice/viewInvoice/` + id + "/sales";
      const getInvDetails: any = await GET(url, null);
      if (getInvDetails?.status) {
        setDetails(getInvDetails?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsFullLoading(false);
    }
  };

  async function genrateTemplate(type: any, emaildata: any) {
    try {
      if (type == "downLoad") {
        setDownloadLoading(true);
      }
      let obj = {
        user: User.user,
        customer: details?.invoiceDetails?.customer,
        sale: details?.invoiceDetails,
        productlist: details?.invoiceItems,
        bankList: details?.banking,
        vatTotal: _tatalVat,
        netTotal: _subTotal,
        Discount: _overollDiscount,
        round: amountAdded,
        total: details?.invoiceDetails?.total,
        vatRate: _tatalVat,
        isPaymentInfo: false,
        pagetype: "Sales Invoice",
        selectedBank: User.user.bankInfo,
      };
      let templates: any = null;
      templates = template1(obj);
      if (type == "email") {
        sendMailPdf(templates, emaildata);
      } else if (type == "print") {
        setTemplate(templates);
        setModalOpen(true);
      } else {
        await downLoadPdf(templates);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.error(error);
      setDownloadLoading(false);
    }
  }
  const downLoadPdf = async (templates: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    templateContent = templateContent.replace('\\"', '"');
    let updatedTemplateContent = templates
      .replace(/[\r\n]/g, "")
      .replace(/\\"/g, "")
      .replace(/[\u0100-\uffff]/g, "");

    const encodedString = btoa(
      unescape(encodeURIComponent(updatedTemplateContent))
    );
    const pdf_url = `https://pdf.taxgoglobal.com/getPdf`;
    const pdfData = {
      filename: "Sales Invoice",
      html: encodedString,
      isDownload: true,
      sendEmail: false,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const pdfBlob = await response.arrayBuffer();
    const blob = new Blob([pdfBlob], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `sales${
      details?.invoiceDetails?.customer?.bus_name ||
      details?.invoiceDetails?.customer?.name
    }_${details?.invoiceDetails?.invoiceno}_${moment(new Date()).format(
      "DD-MM-YYYY"
    )}`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const sendMailPdf = async (templates: any, email: any) => {
    let templateContent = templates.replace("\r\n", "");
    templateContent = templateContent.replace('\\"', '"');
    templateContent = templateContent.replace('\\"', '"');
    let updatedTemplateContent = templates
      .replace(/[\r\n]/g, "")
      .replace(/\\"/g, "")
      .replace(/[\u0100-\uffff]/g, "");

    const encodedString = btoa(
      unescape(encodeURIComponent(updatedTemplateContent))
    );
    const pdf_url = `https://pdf.taxgoglobal.com/getPdf`;
    const pdfData = {
      email: email,
      filename: "Sales Invoice.pdf",
      html: encodedString,
      isDownload: false,
      sendEmail: true,
      type: "",
      userid: "",
    };
    const token = User.token;

    const response = await fetch(pdf_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pdfData),
    });

    if (response.ok) {
      notification.success({ message: "Email Successfully Sent" });
      setEmailModal(false);
    }
    if (!response.ok) {
      notification.error({
        message:
          "Apologies, there was an error when attempting to send the email.",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  };
  const content = (
    <div
      className="h-auto d-flex flex-column overflow-y-auto"
      style={{
        maxHeight: 270,
      }}
    >
      {rowData?.imei?.map((item: any) => {
        return (
          <div className="mb-3">
            <Input size="small" defaultValue={item} />
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div className="d-block d-lg-none">
        <Header isNotSerach={true} />
      </div>
      <br />
      <Container>
        <OuterPageHeader
          title={(t as any)("invoice.text_13")}
          children={
            <div>
              <Tooltip
                title={(t as any)("invoice.text_14")}
                mouseEnterDelay={0.5}
                arrow={false}
                color="white"
                overlayClassName="toolTip-Card"
                overlayInnerStyle={{
                  color: "#000000",
                  marginTop: 5,
                  fontSize: "14px",
                }}
                placement={"bottom"}
              >
                <Button onClick={() => genrateTemplate("print", {})}>
                  <BsPrinter size={20} />
                </Button>
              </Tooltip>
              &nbsp;
              <Tooltip
                title={(t as any)("invoice.text_15")}
                mouseEnterDelay={0.5}
                arrow={false}
                color="white"
                overlayClassName="toolTip-Card"
                overlayInnerStyle={{
                  color: "#000000",
                  marginTop: 5,
                  fontSize: "14px",
                }}
                placement={"bottom"}
              >
                <Button
                  onClick={() => genrateTemplate("downLoad", {})}
                  loading={downloadLoading}
                >
                  <BsDownload size={20} />
                </Button>
              </Tooltip>
              &nbsp;
              <Tooltip
                title={(t as any)("invoice.text_16")}
                mouseEnterDelay={0.5}
                arrow={false}
                color="white"
                overlayClassName="toolTip-Card"
                overlayInnerStyle={{
                  color: "#000000",
                  marginTop: 5,
                  fontSize: "14px",
                }}
                placement={"bottom"}
              >
                <Button onClick={() => setEmailModal(true)}>
                  <BsMailboxFlag size={20} />
                </Button>
              </Tooltip>
              &nbsp;
            </div>
          }
        />
        <br />
        {isFullLoading ? (
          <LoadingBox />
        ) : (
          <Card>
            <Row>
              <Col md="12">
                <div className="salesInvoice-Header">
                  {(t as any)("invoice.text_17")}
                </div>

                <Table bordered>
                  <tbody>
                    <tr>
                      <td className="items-head">
                        {(t as any)("invoice.text_18")}
                      </td>
                      <td>
                        <strong>{details?.invoiceDetails?.invoiceno}</strong>
                      </td>
                      <td className="items-head">
                        {(t as any)("invoice.text_19")}
                      </td>
                      <td className="items-value">
                        {details?.invoiceDetails?.reference}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head">
                        {(t as any)("invoice.text_20")}
                      </td>
                      <td className="items-value">
                        {dayjs(details?.invoiceDetails?.sdate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td className="items-head">
                        {(t as any)("invoice.text_21")}
                      </td>
                      <td className="items-value">
                        {dayjs(details?.invoiceDetails?.ldate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="items-head">
                        {(t as any)("invoice.text_22")}
                      </td>
                      <td className="items-value">
                        {details?.invoiceDetails?.inaddress}
                      </td>
                      <td className="items-head">
                        {(t as any)("invoice.text_23")}
                      </td>
                      <td className="items-value">
                        {details?.invoiceDetails?.cname},
                        {details?.invoiceDetails?.deladdress}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col style={{ overflow: "auto" }}>
                <div className="salesInvoice-SubHeader ">
                  {(t as any)("invoice.text_24")}
                </div>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{(t as any)("invoice.text_25")}</th>
                      <th>{(t as any)("invoice.text_26")}</th>
                      <th>{(t as any)("invoice.text_27")}</th>
                      <th>{(t as any)("invoice.text_28")}</th>
                      {User?.user?.companyInfo?.tax === "gst" ? (
                        <>
                          <th>{(t as any)("invoice.text_29")}</th>
                          {User?.user?.companyInfo?.isOtherTerritory ? (
                            <th>{(t as any)("invoice.text_30")}</th>
                          ) : (
                            <>
                              <th>{(t as any)("invoice.text_31")}</th>
                              <th>{(t as any)("invoice.text_32")}</th>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <th>{(t as any)("invoice.text_33")}</th>
                          <th>{(t as any)("invoice.text_34")}</th>
                        </>
                      )}
                      <th>{(t as any)("invoice.text_35")}</th>
                      <th>{(t as any)("invoice.text_36")}</th>
                      <th>{(t as any)("invoice.text_37")}</th>
                      <th>{(t as any)("invoice.text_38")}</th>
                      <th>IMEI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.invoiceItems?.map((item: any, index: any) => {
                      let vatPercent = Number(item.vat);
                      discountAmount = 0;
                      let vatAmount = Number(item.vatamt);

                      if (item.includevat == 1) {
                        _subTotal =
                          Number(item.costprice) * Number(item.quantity) -
                          vatAmount +
                          _subTotal;
                      } else {
                        _subTotal =
                          Number(item.costprice) * Number(item.quantity) +
                          _subTotal;
                      }
                      if (item.discount > 0) {
                        const discountRate = item.discount / 100;
                        discountAmount =
                          item.includevat == 1
                            ? Number(item.costprice) *
                              Number(item.quantity) *
                              discountRate
                            : (Number(item.costprice) * Number(item.quantity) +
                                vatAmount) *
                              discountRate;
                      }

                      _tatalVat = _tatalVat + vatAmount;
                      _overollDiscount = _overollDiscount + discountAmount;
                      let _totalAmount =
                        _subTotal + _tatalVat - _overollDiscount;
                      let roundedNumber = Math.round(_totalAmount);
                      amountAdded = roundedNumber - _totalAmount;

                      return (
                        <tr>
                          <td>{item?.description}</td>
                          <td>{item?.quantity}</td>
                          <td>{item.product.unit}</td>
                          <td>{item?.costprice}</td>
                          {User?.user?.companyInfo?.tax === "gst" ? (
                            <>
                              <td>{item?.vat}%</td>
                              {User?.user?.companyInfo?.isOtherTerritory ? (
                                <td>{item?.vatamt}</td>
                              ) : (
                                <>
                                  <td>{item?.vatamt / 2}</td>
                                  <td>{item?.vatamt / 2}</td>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <td>{item?.vat}%</td>
                              <td>{item?.vatamt}</td>
                            </>
                          )}

                          <td style={{ textAlign: "center" }}>
                            <Checkbox
                              checked={item.includevat == 1 ? true : false}
                            />
                          </td>
                          <td>{item?.discount}</td>
                          <td>{discountAmount.toFixed(2)}</td>
                          <td>{item?.total}</td>
                          <td className="text-center">
                            <Space wrap>
                              <Popover
                                placement="topRight"
                                content={content}
                                title="IMEI"
                                trigger="click"
                              >
                                <Tooltip
                                  title="View IMEI number"
                                  visible={tooltipVisible[index] ?? false}
                                >
                                  <SlEye
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={() => handleIconLeave(index)}
                                    onClick={() => {
                                      handleIconLeave(index);
                                      setrowData(item);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    size={18}
                                  />
                                </Tooltip>
                              </Popover>
                            </Space>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col sm={8}></Col>
              <Col sm={4}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>{(t as any)("invoice.text_39")}</td>
                      <td>
                        {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                        {_subTotal?.toFixed(2)}
                      </td>
                    </tr>
                    {User?.user?.companyInfo?.tax === "gst" ? (
                      <>
                        {User?.user?.companyInfo?.isOtherTerritory ? (
                          <tr>
                            <td>{(t as any)("invoice.text_40")}</td>
                            <td>
                              {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                              {_tatalVat?.toFixed(2)}
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <td>{(t as any)("invoice.text_41")}</td>
                              <td>
                                {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                                {(_tatalVat / 2)?.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>{(t as any)("invoice.text_42")}</td>
                              <td>
                                {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                                {(_tatalVat / 2)?.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td>{(t as any)("invoice.text_43")}</td>
                        <td>
                          {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                          {_tatalVat?.toFixed(2)}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>{(t as any)("invoice.text_44")}</td>
                      <td>
                        {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                        {_overollDiscount?.toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>ROUND OFF</td>
                      <td>{amountAdded?.toFixed(2)}</td>
                    </tr> */}
                    <tr>
                      <td className="items-head">
                        {(t as any)("invoice.text_45")}
                      </td>
                      <td className="items-head">
                        {User?.user?.companyInfo?.countryInfo?.symbol}{" "}
                        {details?.invoiceDetails?.total}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card>
        )}
        {emailModal ? (
          <SendMailModal
            open={emailModal}
            close={() => setEmailModal(false)}
            onFinish={(val: any) => genrateTemplate("email", val)}
            ownMail={User.user.email}
            Attachment={`${details?.invoiceDetails?.customer?.bus_name}_${
              details?.invoiceDetails?.invoiceno
            }_${moment(new Date()).format("DD-MM-YYYY")}`}
            defaultValue={{
              to: details?.invoiceDetails?.customer?.email,
              subject: `Performa Invoice ${details?.invoiceDetails?.invoiceno}`,
              content: User?.user?.companyInfo?.defaultmail,
            }}
            fileName={`SalesInvoice${new Date()}.pdf`}
          />
        ) : null}
        {modalOpen ? (
          <PrintModal
            open={modalOpen}
            width={1200}
            navigation={true}
            modalClose={(val: any) => setModalOpen(val)}
            template={template}
          />
        ) : null}
      </Container>
      <br />
    </>
  );
}

export default SaleInvoiceView;
