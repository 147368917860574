import React from "react";
import Header from "../../component/header";
import { Container } from "react-bootstrap";
import "./styles.scss";
import Footer from "../../component/footer";
function TermsAndCondition() {
  return (
    <>
      <Header />
      <Container>
        <div className="Box1">
          <div className="Txt1">Terms & Conditions</div>
          <div className="Txt2">
            Welcome to Retail Xpress. By accessing or using our services, you
            agree to be bound by these terms and conditions. If you do not agree
            with any part of these terms, please do not use our services.
          </div>
          <div className="Txt2">
            Our services are provided solely for your personal, non-commercial
            use. You agree to use our services in compliance with all applicable
            laws and regulations. Unauthorized use of our services is
            prohibited.
          </div>
          <div className="Txt2">
            Retail Xpress does not collect any personal data from users. We
            respect your privacy and are committed to ensuring that your
            experience with us is secure and private. As we do not collect any
            data, we also do not store, process, or share any personal
            information.
          </div>
          <div className="Txt2">
            All content and materials on Retail Xpress, including but not
            limited to text, graphics, logos, and software, are the property of
            Retail Xpress or its licensors and are protected by intellectual
            property laws. You may not use, reproduce, or distribute any content
            without our express permission.
          </div>
          <div className="Txt2">
            Retail Xpress is provided on an "as is" and "as available" basis. We
            do not guarantee that our services will be uninterrupted or
            error-free. To the maximum extent permitted by law, Retail Xpress
            disclaims all warranties, express or implied, and will not be liable
            for any damages arising from the use of our services.
          </div>
          <div className="Txt2">
            We reserve the right to modify these terms and conditions at any
            time. Any changes will be effective immediately upon posting on our
            website. Your continued use of our services constitutes your
            acceptance of the revised terms.
          </div>
          <div className="Txt2">
            If you have any questions or concerns about these terms and
            conditions, please contact us at{" "}
            <a href="https://www.retailxpress.net/contact">
              https://www.retailxpress.net/contact
            </a>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default TermsAndCondition;
