import { Button, Modal } from 'antd';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const QRScannerModal = (props: any) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const qrCodeSuccessCallback = (decodedText: string) => {
    props.qrCodeSuccessCallback(decodedText)
    setVisible(false);
  };

  const qrCodeErrorCallback = (errorMessage: string) => {
    console.error('Error scanning QR code:', errorMessage);
  };

  useEffect(() => {
    if (visible) {
      const html5QrcodeScanner = new Html5QrcodeScanner(
        "html5qr-code-full-region",
        {
          fps: 10,
          qrbox: 250,
          aspectRatio: 1,
          disableFlip: false,
        },
        true
      );
      html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
      return () => {
        html5QrcodeScanner.clear().catch((error: any) => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
      };
    }
  }, [visible]);

  return (
    <>
      <Button onClick={() => setVisible(true)} block>
        {(t as any)("header.text_2")}
      </Button>
      <Modal
        title="Scan QR Code"
        visible={visible}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div id="html5qr-code-full-region" style={{ width: '100%' }} />
      </Modal>
    </>
  );
};

export default QRScannerModal;
