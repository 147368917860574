import Footer from "../../component/footer";
import Header from "../../component/header";
import Block1 from "./component/block1";
import Block2 from "./component/block2";
import Block3 from "./component/block3";
import Block4 from "./component/block4";
import Block5 from "./component/block5";
import RetailXpressVideo from "./component/video";
import "./styles.scss";
function HomeScreen() {
  return (
    <>
      {/* <Container> */}
      <Header />
      <Block1 />
      <br />
      <RetailXpressVideo />
      <br />
      <Block2 />
      <Block3 />
      <Block4 />
      <Block5 />
      <Footer />
      {/* </Container> */}
    </>
  );
}

export default HomeScreen;
