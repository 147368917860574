import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import QrReader from "react-qr-scanner";

const QRScan = ({ open, close, qrData }: any) => {
  const [delay, setDelay] = useState(1);
  const [result, setResult] = useState("No result");

  const handleScan = (data: any) => {
    if (data) {
      qrData(data)
      close()
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <Modal
      open={open}
      width={500}
      closable={false}
      footer={false}
      onCancel={() => close()}
    >
      <Row>
        <Col xs={12}>
          <QrReader
            delay={delay}
            style={{
              height: 240,
              width: '100%'
            }}
            onError={handleError}
            onScan={handleScan}
          />
        </Col>
        <Col xs={6}></Col>
        <Col xs={6}>
          <Button
            block
            size="large"
            style={{
              marginTop: '10px'
            }}
            onClick={() => close()}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default QRScan;