import { Button, Space } from "antd";
import { IoMdClose } from "react-icons/io";

function ListOrderdItem(props: any) {
  return (
    <div className="order-ListItem">
      <div className="order-ListItemtxt">
        {Number(props?.index) + 1}.
      </div>
      <div style={{ flex: 1 }}>
        <div className="order-ListItemtxt1">
          {props?.item?.idescription}
        </div>
        <div className="order-ListItemtxt2">
          {`${props?.currencycode ? props?.currencycode : ''} ${props?.item?.rate}`} | {props?.item?.quantity_no}{" "}
          {props?.item?.unitDetails?.unit}
        </div>
      </div>
      <div>
        <Space.Compact block>
          <Button
            size="small"
            onClick={() => props.minus(props.item.id)}
          >
            -
          </Button>
          <input style={{
            border: '1px solid #d3d3d3',
            outline: 'none',
            textAlign: 'center',
            width: '30px'
          }}
            onInput={(e: any) => {
              e.currentTarget.value = e.currentTarget.value.replace(
                /[^0-9]/g,
                ""
              );
            }}
            value={props.item.quantity_no}
            onChange={(e) => props.enter({
              id: props.item.id,
              quantity: Number(e.target.value)
            })
            }
          />
          {/* <Button size="small">{props.item.quantity_no}</Button> */}
          <Button
            size="small"
            onClick={() => props.add(props.item.id)}
            style={{ borderLeft: 'none' }}
          >
            +
          </Button>
        </Space.Compact>
      </div>
      &nbsp; &nbsp; &nbsp;
      <div>
        <Button
          size="small"
          danger
          onClick={() => props.isChange(props.item.id)}
        >
          <IoMdClose />
        </Button>
      </div>
    </div>
  );
}

export default ListOrderdItem;
