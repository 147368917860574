// import { useEffect, useRef, useState } from "react";
// import { FaRegPauseCircle } from "react-icons/fa";
// import { MdPlayCircleFilled } from "react-icons/md";

// function RetailXpressVideo() {
//   const [isPlaying, setIsPlaying] = useState<any>(false);
//   const [isLoading, setIsLoading] = useState<any>(true);
//   const videoRef = useRef<any>(null);

//   useEffect(() => {
//     document.body.addEventListener("click", handleBodyClick);
//     return () => {
//       document.body.removeEventListener("click", handleBodyClick);
//     };
//   }, []);

//   const handleBodyClick = () => {
//     if (isPlaying) {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   const togglePlayPause = (e: any) => {
//     e.stopPropagation();
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   const handleVideoLoaded = () => {
//     setIsLoading(false);
//   };

//   return (
//     <>
//       <div className="RetailXpressVideo-Box1" onClick={togglePlayPause}>
//         {/* {isLoading && <div>Loading...</div>} */}
//         <video
//           ref={videoRef}
//           className="RetailXpressVideo-Box2"
//           src="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/retailXpress/retailXpressDemo.mp4"
//           onLoadedData={handleVideoLoaded}
//         />
//         {isPlaying ? (
//           <FaRegPauseCircle
//             onClick={togglePlayPause}
//             color="rgba(128, 128, 128, 0)"
//             size={80}
//             className="RetailXpressVideo-Box3"
//           />
//         ) : (
//           <MdPlayCircleFilled
//             onClick={togglePlayPause}
//             color="#fff"
//             size={120}
//             className="RetailXpressVideo-Box3"
//           />
//         )}
//       </div>
//     </>
//   );
// }

// export default RetailXpressVideo;

import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../styles.scss";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { useTranslation } from "react-i18next"


const RetailXpressVideo = () => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayClick = () => {
    const video: any = document.getElementById("myVideo");
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const handleRegularButtonClick = () => { };
  return (
    <div style={{ backgroundColor: "#f4f6f8" }}>
      <Container>
        <Row className="g-0">
          <Col md={6}>
            <div className="video-box1">
              <div className="video-text1">
                <b>
                {(t as any)("websitehome.win")}
                </b>
              </div>
              <br />
              <div className="video-text2">
              {(t as any)("websitehome.misstext")}
              </div>
              <br />
              <br />
              <div className="video-button" onClick={handlePlayClick}>
                <button
                  className="learn-more"
                  onClick={handleRegularButtonClick}
                >
                  <span className="circle" aria-hidden="true">
                    {isPlaying == true ? (
                      <FaPause
                        style={{ marginLeft: 14 }}
                        color="#fff"
                        size={22}
                      />
                    ) : (
                      <FaPlay
                        style={{ marginLeft: 15 }}
                        color="#fff"
                        size={22}
                      />
                    )}
                  </span>
                  <span className="button-text">
                    {isPlaying == true ? (t as any)("websitehome.pausetxt") : (t as any)("websitehome.playtxt")}
                  </span>
                </button>
              </div>
            </div>
          </Col>
          <Col
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card>
              <video
                id="myVideo"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                onEnded={() => setIsPlaying(false)}
              >
                <source
                  src="https://bairuha-bucket.s3.ap-south-1.amazonaws.com/retailXpress/retailXpressDemo.mp4"
                  type="video/mp4"
                />
              </video>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default RetailXpressVideo;
