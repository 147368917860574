import { Button, Card, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import rocket from '../../assets/images/Rocket.svg';
import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import OuterPageHeader from "../OuterHeader/OuterPageHeader";
import LoadingBox from "../loadingBox";
import "./styles.scss";
import Header from "../retailHeader/header";
import { useTranslation } from "react-i18next";

function Subscription() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState<any>();
  const [paymentLog, setPaymentLog] = useState<any>();
  const navigate = useNavigate();

  const fetchSbscriptionDetails = async () => {
    try {
      setIsLoading(true);
      let url = API.USER_SUBSCRIBED_PLAN;
      const response: any = await GET(url, null);
      if (response?.status) {
        setPlanDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubscriptionLog = async () => {
    try {
      setIsLoading(true);
      let url = API.USER_SUBSCRIPTION_LIST;
      const response: any = await GET(url, null);
      if (response.status) {
        setPaymentLog(response.data);
      } else {
        notification.error({
          message: "Failed",
          description: response.message,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const daysUntilDue = (today: any, dueDate: any) => {
    let start: any = new Date(today);
    let due: any = new Date(dueDate);
    let timeDifference = due - start;
    let daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  useEffect(() => {
    fetchSbscriptionDetails();
    fetchSubscriptionLog();
  }, []);

  return (
    <>
      <div className='d-block d-lg-none'>
        <Header isNotSerach={true} />
      </div>
      <Container>
        <br />
        <OuterPageHeader title={(t as any)("subscription.text")} />
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <Card className="subscription">
              <Row>
                <Col md={6}>
                  <div className="plan-container">
                    {planDetails?.period === 1
                      ? (t as any)("subscription.text_31")
                      : planDetails?.period === 12
                        ? (t as any)("subscription.text_32")
                        : planDetails?.period === 24
                          ? (t as any)("subscription.text_33")
                          : (t as any)("subscription.text_34")}
                  </div>
                  {planDetails?.retailXpressWithTaxgo ? (
                    <div className="subscriptionScreen-companyContainer"
                      style={{ backgroundColor: '#FFF2DB' }}
                    >
                      <div className="textCompanyText">
                        <div className="checkboxContainer">
                          <span className="subscription-text3" style={{ color: '#85642B' }} >{(t as any)("subscription.text_2")}</span>
                        </div>
                        <div className="formLabel" style={{ marginTop: 4, color: '#85642B' }}>
                          {(t as any)("subscription.text_10")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="subscriptionScreen-companyContainer"
                      style={{ backgroundColor: '#E5F3FF' }}
                    >
                      <div className="textCompanyText">
                        <div className="checkboxContainer">
                          <span className="subscription-text3" style={{ color: '#2B5985' }} >{(t as any)("subscription.text_9")}</span>
                        </div>
                        <div className="formLabel" style={{ marginTop: 4, color: '#2B5985' }}>
                          {(t as any)("subscription.text_22")}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="day-container">
                    <div
                      className="card"
                      style={{
                        color: daysUntilDue(new Date(), moment(planDetails?.subscriptionExpiry)) <= 10 ? 'red' : '#34b628'
                      }}
                    >
                      <div className="text-1">
                        {daysUntilDue(new Date(), moment(planDetails?.subscriptionExpiry))}
                      </div>
                      <div className="text-2">{(t as any)("subscription.text_6")}</div>
                    </div>
                    <div className="card-2">
                      <div className="text-1">{(t as any)("subscription.text_4")}</div>
                      <div className="text-2">{(t as any)("subscription.text_5")}  {moment(planDetails?.subscriptionExpiry).format('YYYY-MM-DD')}
                        {moment(planDetails?.subscriptionExpiry).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD") ? (
                          <span style={{ color: "red" }}>
                            {moment(planDetails?.subscriptionExpiry).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD") ? ` - ( ${(t as any)("subscription.text_35")} )` : ` - ( {(t as any)("subscription.text_36")} )`}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <img
                        src={rocket}
                        alt="upgrade"
                        className="profile-subscription-rocket-image"
                        style={{ height: '30vh' }}
                      />
                    </Col>
                    <Col
                      md={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: 10,
                      }}
                    >
                      <p className="profile-subscription-Txt2">
                        {(t as any)("subscription.text_7")}
                      </p>
                      <div>
                        <Button
                          type="primary"
                          onClick={() => navigate("/auth/subscription/upgrade")}
                        >
                          {(t as any)("subscription.text_8")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />

              <hr />
              <br />
              <p className="profile-subscription-Txt2">{(t as any)("subscription.text_11")}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{(t as any)("subscription.text_12")}</th>
                    <th>{(t as any)("subscription.text_13")}</th>
                    <th>{(t as any)("subscription.text_14")}</th>
                    <th>{(t as any)("subscription.text_15")}</th>
                    <th>{(t as any)("subscription.text_16")}</th>
                    {/* <th>Invoice</th> */}
                  </tr>
                </thead>
                <tbody>
                  {paymentLog?.map((item: any, index: number) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{moment(item?.createdAt).format("YYYY-MM-DD")}</td>
                      <td>{item?.subscriptionPlan} Months</td>
                      <td>{item?.amount}</td>
                      <td>
                        {item?.status === "succeeded" ? "Success" : "Failed"}
                      </td>
                      {/* <td>Download</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <br />
          </>
        )}
      </Container >
    </>
  );
}

export default Subscription;
