import { Pagination, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import OuterPageHeader from '../../component/OuterHeader/OuterPageHeader';
import LoadingBox from '../../component/loadingBox';
import Header from '../../component/retailHeader/header';
import { POST } from '../../utils/apiCalls';
import ReportCard from './component/reportCard';
import ReportList from './component/reportList';
import ReportListInvoice from './component/reportListInvoice';
import { useTranslation } from 'react-i18next';

function Report() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);

    const [list, setList] = useState([]);
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(true);
    const [page, setPage] = useState(1);
    const [page2, setPage2] = useState(1);
    const [meta, setMeta] = useState<any>({});
    const [meta2, setMeta2] = useState<any>({});
    const [report, setReport] = useState<any>([]);
    const [shift, setShift] = useState<any>(0);

    useEffect(() => {
        fetchReport()
    }, [page])

    useEffect(() => {
        if (shift !== 0) {
            fetchListByShift(shift)
        } else {
            setIsLoading2(false)
        }
    }, [page2, shift])

    const fetchListByShift = async (val: any) => {
        try {
            setIsLoading2(true);
            let obj = {
                adminid: user?.id,
                companyid: user?.staff?.companyid,
                staffid: user?.staff?.id,
                shiftid: val,
                type: 'Customer Receipt',
                page: page2,
                take: 10
            };
            let url = `StaffTransactions/list-by-shift`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setData(response);
                setMeta2(response.meta)
                setIsLoading2(false);
            } else {
                setIsLoading2(false);
            }
        } catch (error) {
            setIsLoading2(false);
        }
    };

    const fetchReport = async () => {
        try {
            setIsLoading(true);
            let obj = {
                adminId: user?.id,
                companyid: user?.staff?.companyid,
                staffid: user?.staff?.id,
                page: page,
                take: 10
            };
            let url = `counter_details/list-by-shift`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                let obj = await CreateReportObj(response)
                setReport(obj)
                setMeta(response.meta)
                setList(response.data);
                if (shift === 0) {
                    setShift(response.data[0].id)
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const CreateReportObj = (data: any) => {
        let reportObj = [
            {
                title: (t as any)("report.text_1"),
                value: data.total_sales || 0,
                icon: 'TbFileInvoice',
                icon2: 'FcSalesPerformance',
                message: (t as any)("report.text_2"),
                bgcolor: '#ccffc4',
                color: '#68A65C'
            },
            {
                title: (t as any)("report.text_3"),
                value: data.totalShift || 0,
                icon: 'DiOpenshift',
                icon2: 'FcSynchronize',
                message: (t as any)("report.text_4"),
                bgcolor: '#ebd9ff',
                color: '#895ECE'
            },
            {
                title: (t as any)("report.text_5"),
                value: data.closed_invoices || 0,
                icon: 'FaCreativeCommonsNc',
                icon2: 'FcPrivacy',
                message: (t as any)("report.text_6"),
                bgcolor: '#ffcaca',
                color: '#CA2D32',
            },
            {
                title: (t as any)("report.text_7"),
                value: data.open_invoices || 0,
                icon: 'FaMoneyBillWave',
                icon2: 'FcUnlock',
                message: (t as any)("report.text_8"),
                bgcolor: '#ccdaff',
                color: '#2252DD'
            },
        ]
        return reportObj
    }

    const columns: any = [
        {
            title: (t as any)("report.text_9"),
            dataIndex: "invoiceno",
            key: 0,
        },
        {
            title: (t as any)("report.text_10"),
            dataIndex: "saletype",
        },
        {
            title: (t as any)("report.text_11"),
            dataIndex: "created_at",
            render: (date: string) => {
                return (
                    <div style={{ color: "#BDBDBD" }}>
                        {moment(date).format("YYYY-MM-DD")}
                    </div>
                );
            },
        },
        {
            title: (t as any)("report.text_12"),
            dataIndex: "total",
        },
        {
            title: (t as any)("report.text_13"),
            dataIndex: "status",
            render: (status: any) => {
                return (
                    <Tag
                        className="paidStatus"
                        color={status === "closed" ? "#38BE02" : "#cd201f"}
                    >
                        {status}
                    </Tag>
                );
            },
        },
        {
            title: (t as any)("report.text_14"),
            dataIndex: "paid_status",
            render: (paid_status: any) => {
                return (
                    paid_status == 0 ? (
                        <Tag className="paidStatus" color={"#cd201f"}>
                            {(t as any)("report.text_14.1")}
                        </Tag>
                    ) : paid_status == 1 ? (
                        <Tag className="paidStatus" color={"#FE9900"}>
                            {(t as any)("report.text_14.2")}
                        </Tag>
                    ) : (
                        <Tag className="paidStatus" color={"#38BE02"}>
                            {(t as any)("report.text_14.3")}
                        </Tag>
                    )
                );
            },
        },
    ];

    return (
        <div>
            <div className='d-block d-lg-none'>
                <Header isNotSerach={true} />
            </div>
            <br />
            <Container>
                <OuterPageHeader title={(t as any)("report.text")} />
                <br />
                <Row className='g-0'>
                    {report?.map((item: any, index: number) => {
                        return (
                            <Col xs={6} lg={3}>
                                <ReportCard item={item} index={index} length={report.length} />
                            </Col>
                        )
                    })}
                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <Col lg={5}>
                                <div className='reportCard2'>
                                    <div className='reportListBox'>
                                        {isLoading ? (
                                            <LoadingBox />
                                        ) : (
                                            <ReportList list={list} onSelect={(val: any) => {
                                                fetchListByShift(val)
                                                setShift(val)
                                            }}
                                                shiftId={shift}
                                            />
                                        )}
                                    </div>
                                    <br />
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Pagination
                                            total={meta?.totalCount}
                                            showSizeChanger={false}
                                            showTotal={(total) => `${(t as any)("report.text_12")} ${meta?.totalCount} ${(t as any)("report.text_15")}`}
                                            onChange={(page) => setPage(page)}
                                        />
                                    </div>
                                    <br />
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className='reportCard2'>
                                    <div className='reportListBox'>
                                        {isLoading2 ? (
                                            <LoadingBox />
                                        ) : (
                                            <ReportListInvoice data={data?.data} columns={columns} />
                                        )}
                                    </div>
                                    <br />
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Pagination
                                            total={meta2?.total}
                                            showSizeChanger={false}
                                            showTotal={(total) => `${(t as any)("report.text_12")} ${meta2?.total || 0} ${(t as any)("report.text_15")}`}
                                            onChange={(page) => setPage2(page)}
                                        />
                                    </div>
                                    <br />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row >
                <br />
                <br />
                <br />
            </Container >
        </div >
    )
}

export default Report