import { Button, Form, Input, Modal, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { GET, POST } from '../../../utils/apiCalls';
import { useTranslation } from 'react-i18next';

function FormPurchace({ open, close, item, bankList, getDatas }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);

    const [form] = Form.useForm();

    const [ledger, setLedger] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [totalRatePrice, settotalRatePrice] = useState<any>();
    const [vatamt, setvatamt] = useState<any>();

    useEffect(() => {
        let vatamt1
        let totalRate1
        let totalRatePrice1
        LoadLedger();
        if (item?.includevat === '1.00') {
            const taxebaleValue = Number(item?.costprice) / ((Number(item?.vat) / 100) + 1);
            vatamt1 = Number(item.costprice) || Number(item?.rate) - taxebaleValue;
            totalRate1 = taxebaleValue;
            totalRatePrice1 = Number(item?.costprice) || Number(item?.rate);
        } else {
            vatamt1 = Number(item.costprice) || Number(item?.rate) * (Number(item.vat) / 100);
            totalRate1 = Number(item.costprice) || Number(item?.rate) + Number(vatamt1);
            totalRatePrice1 = totalRate1;
        }
        settotalRatePrice(totalRatePrice1)
        setvatamt(vatamt1)
        form.setFieldsValue({
            quantity: 1,
            price: item?.includevat === '1.00' ? totalRate1?.toFixed(2) : item?.costprice || item?.rate,
            vat: item.vat,
            vatamt: vatamt1.toFixed(2),
            total: item?.includevat !== '1.00' ? totalRatePrice1?.toFixed(2) : item?.costprice || item?.rate,
            method: 'cash'
        });
    }, []);

    const LoadLedger = async () => {
        try {
            const URL = `account_master/getLedgerById/12`;
            const data: any = await GET(URL, null);
            setLedger(data?.data);
        } catch (error) {
            console.error("Error loading ledger:", error);
        }
    };

    const onValuesChange = async (_: any, arr: any) => {
        let vat;
        let total;
        if (item?.includevat === '1.00') {
            vat = Number(vatamt) * Number(arr.quantity);
            total = Number(item.costprice) * Number(arr.quantity);
        } else {
            vat = Number(vatamt) * Number(arr.quantity);
            total = Number(totalRatePrice) * Number(arr.quantity);
        }
        form.setFieldsValue({
            vatamt: vat.toFixed(2) || 0,
            total: total.toFixed(2) || 0,
        });
    };

    const onFinish = async (val: any) => {
        try {
            setIsLoading(true);
            const purchaseDetails = {
                sdate: new Date(),
                ldate: new Date(),
                invoiceno: item.invoiceno,
                quotes: user?.companyInfo?.cusNotes,
                status: '0',
                refid: null,
            };
            const productLedger = {
                category: "13",
                id: 1,
                laccount: "Sales-Products",
                nominalcode: "4000",
            };
            const column = [{
                id: item.id,
                discount: '0.00',
                discountamt: '0.00',
                productId: item.id,
                product: item,
                idescription: item.idescription,
                description: item.idescription,
                vat: item?.vat?.toFixed(2),
                includevat: item.vat === '1.00',
                incomeTax: item?.vat?.toFixed(2),
                percentage: '0.00',
                costprice: Number(item.costprice),
                ledgerDetails: productLedger,
                ledger: productLedger,
                quantity: Number(val.quantity),
                total: val?.total,
                vatamt: val?.vatamt,
                vatamount: val?.vatamt,
                incomeTaxAmount: val.vatamt,
                itemorder: 1,
                seriesNo:counter?.counter?.location
            }];
            const payload = {
                supplier: user?.staff,
                pList: column,
                purchase: purchaseDetails,
                invoiceno: val.invoiceno,
                sdate: new Date(),
                ldate: new Date(),
                inaddress: '-',
                deladdress: '-',
                terms: '-',
                quotes: '-',
                adminid: user?.id,
                status: '0',
                issued: "yes",
                type: "purchase",
                pagetype: "1",
                total: Number(val.total),
                userid: user?.id,
                userdate: new Date(),
                attachDoc: "",
                attachImage: "",
                paymentInfo: {
                    id: 0,
                    bankid: 0,
                    outstanding: 0,
                    amount: Number(val.total),
                    date: dayjs(new Date(), "YYYY-MM-DD"),
                    type: '-',
                    paidmethod: '-',
                },
                saletype: 'Purchase',
                ledger: ledger,
                refid: null,
                quantity: val.quantity,
                total_vat: val.vatamt,
                overall_discount: 0,
                taxable_value: Number(val.price) * Number(val.quantity),
                createdBy: user?.staff?.id,
                companyid: user?.companyInfo?.id,
                usertype: "staff",
                counterid: Number(counter?.counter_id),
                shiftid: counter.id,
                seriesNo:counter?.counter?.location
            };
            const res: any = await POST('purchaseinvoice/add/staff_create_purchase', payload);
            if (res.status) {
                notification.success({
                    message: "Purchase Added Successfully",
                });
                setIsLoading(false);
                close();
                getDatas();
            } else {
                notification.error({
                    message: res.message || "Failed to add purchase",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error adding purchase:", error);
            notification.error({
                message: 'Server Error. Please try again.',
            });
            setIsLoading(false);
        }
    };
    return (
        <Modal
            open={open}
            width={700}
            closable={true}
            footer={false}
            onCancel={() => close()}
        >
            <div className="Table-Txt">
            {(t as any)("product.text_5")} {item.idescription}
            </div>
            <div>{(t as any)("product.text_6")} {item.idescription}.</div>
            <hr />
            <br />
            <Form
                form={form}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Row>
                    <Col md={6}>
                        <label className="formLabel">{(t as any)("product.label")}</label>
                        <Form.Item
                            name="invoiceno"
                            rules={[
                                {
                                    required: true,
                                    message: (t as any)("product.required"),
                                },
                            ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("product.label_1")}</label>
                        <Form.Item
                            name="quantity"
                            rules={[
                                {
                                    required: true,
                                    message: (t as any)("product.required_1"),
                                },
                            ]}
                        >
                            <Input size='large' suffix={item?.unitDetails?.unit} />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("product.label_2")}</label>
                        <Form.Item
                            name="price"
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <label className="formLabel">{(t as any)("product.label_3")}: *</label>
                        <Form.Item
                            name="vat"
                        >
                            <Input size='large' disabled suffix={'%'} />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("product.label_4")}</label>
                        <Form.Item
                            name="vatamt"
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("product.label_5")}</label>
                        <Form.Item
                            name="total"
                        >
                            <Input size='large' disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                >
                   {(t as any)("product.text_7")}
                </Button>
            </Form>
        </Modal>
    )
}

export default FormPurchace