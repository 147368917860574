import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { LuLogOut } from "react-icons/lu";
import { RiComputerLine } from "react-icons/ri";
import { FiTag } from "react-icons/fi";
import { BsCashCoin } from "react-icons/bs";
import { TbListDetails } from "react-icons/tb";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { MdPointOfSale } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import { TbFileInvoice } from "react-icons/tb";
import { FcSalesPerformance } from "react-icons/fc";
import { FcSynchronize } from "react-icons/fc";
import { FcBiohazard } from "react-icons/fc";
import { FcUnlock } from "react-icons/fc";
import { FcPrivacy } from "react-icons/fc";
import { DiOpenshift } from "react-icons/di";
import { FaCreativeCommonsNc } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa6";
import { FaBalanceScaleRight } from "react-icons/fa";
import { TbCurrency } from "react-icons/tb";
import { FaArrowDownWideShort } from "react-icons/fa6";
import { FaDesktop } from "react-icons/fa";
import { IoStarOutline } from "react-icons/io5";
import { PiUserDuotone } from "react-icons/pi";
import { IoLanguageOutline } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";
import { BsBoxSeam } from "react-icons/bs";

function DynamicIcon(props: any) {
  type IconName =
    | "CgUnavailable"
    | "LuLogOut"
    | "FiTag"
    | "BsCashCoin"
    | "TbListDetails"
    | "RiComputerLine"
    | "HiOutlineShoppingBag"
    | "MdPointOfSale"
    | "VscGraph"
    | "TbFileInvoice"
    | "FcSalesPerformance"
    | "FcSynchronize"
    | "FcBiohazard"
    | "FcUnlock"
    | "FcPrivacy"
    | "DiOpenshift"
    | "FaCreativeCommonsNc"
    | "FaMoneyBillWave"
    | "FaBalanceScaleRight"
    | "TbCurrency"
    | "FaArrowDownWideShort"
    | "FaDesktop"
    | "IoStarOutline"
    | "PiUserDuotone"
    | "IoLanguageOutline"
    | "TbLogout"
    | "BsBoxSeam";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }
  function Icon({ iconName, size = 26, color = "red" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      CgUnavailable: CgUnavailable,
      LuLogOut: LuLogOut,
      FiTag: FiTag,
      BsCashCoin: BsCashCoin,
      TbListDetails: TbListDetails,
      RiComputerLine: RiComputerLine,
      HiOutlineShoppingBag: HiOutlineShoppingBag,
      MdPointOfSale: MdPointOfSale,
      VscGraph: VscGraph,
      TbFileInvoice: TbFileInvoice,
      FcSalesPerformance: FcSalesPerformance,
      FcSynchronize: FcSynchronize,
      FcBiohazard: FcBiohazard,
      FcUnlock: FcUnlock,
      FcPrivacy: FcPrivacy,
      DiOpenshift: DiOpenshift,
      FaCreativeCommonsNc: FaCreativeCommonsNc,
      FaMoneyBillWave: FaMoneyBillWave,
      FaBalanceScaleRight: FaBalanceScaleRight,
      TbCurrency: TbCurrency,
      FaArrowDownWideShort: FaArrowDownWideShort,
      FaDesktop: FaDesktop,
      IoStarOutline: IoStarOutline,
      PiUserDuotone: PiUserDuotone,
      IoLanguageOutline: IoLanguageOutline,
      TbLogout: TbLogout,
      BsBoxSeam: BsBoxSeam,
    };
    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; // set default icon name
    }
    const IconComponent = icons[iconName];
    return <IconComponent size={size} color={props.color} />;
  }
  return <Icon iconName={props.name} size={props.size} />;
}
export default DynamicIcon;
