import {
  Button,
  Form,
  Input,
  notification,
  Checkbox,
  ConfigProvider,
} from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from "../../component/header";
import {
  login,
  setBaseUrl,
  setMailWerify,
  setToken,
} from "../../redux/slices/userSlice";
import {
  CREATEBASEURL,
  POST,
  REGISTERPOST,
} from "../../utils/apiCalls";
import "./styles.scss";
import { jwtDecode } from "jwt-decode";
import image from "../../assets/images/cash_desk.png";
import { useTranslation } from "react-i18next";
import API from "../../config/api";
import { addCounter, isSoleTrader } from "../../redux/slices/retailExpress";

function LoginScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values: any, BASEURL: any) => {
    try {
      let url = "auth/staff/email-login";
      var loginRes: any = await REGISTERPOST(BASEURL + url, {
        ...values,
        type: "staff",
      });
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(
          login({
            data: loginRes?.data,
            auth: true,
          })
        );
        const decoded: any = jwtDecode(loginRes?.data?.token);
        if (decoded?.soleTrader) {
          await getCounterDetails(loginRes?.data);
        }
        if (loginRes?.data.emailverified) {
          dispatch(setMailWerify());
        }
        notification.success({
          message: "Success",
          description: "Logged in successfully",
        });
        navigate("/auth/counter-sale");
        setIsLoading(false);
      } else {
        notification.error({
          message: "Failed",
          description: loginRes?.message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Server Error",
        description: "Failed to login,Please try again later",
      });
      setIsLoading(false);
    }
  };
  async function getCounterDetails(val: any) {
    try {
      let url = API.POST_COUNTER;
      let obj = {
        adminid: val?.id,
        sdate: new Date(),
        sole_trader: true,
        staffid: val?.staff?.id,
        companyid: val?.staff?.companyid,
      };
      const response: any = await POST(url, obj);
      if (response.status === true) {
        dispatch(addCounter(response?.data));
        dispatch(isSoleTrader(true));
      }
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || "Error occurred",
      });
    }
  }

  const onFinish = async (data: any) => {
    try {
      setIsLoading(true);
      let endpoint = "base/staff/activeUrl";
      const response: any = await CREATEBASEURL(endpoint, {
        email: data.email,
      });
      if (response.status) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        submit(data, response?.data?.baseUrl);
      } else {
        notification.error({
          message:
            "Oops! Something went wrong with your sign-up. Please try again later or contact support for help.",
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#18a762",
          fontFamily: "Regular",
          borderRadius: 4,
          colorTextPlaceholder: "#888c99",
        },
      }}
    >
      <div>
        <Header />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="d-none  d-lg-flex justify-content-center align-items-center">
              <div className="login_box">
                <p>{(t as any)("websitelogin.loginsimplify")}</p>
                <div className="login_text">
                  {(t as any)("websitelogin.simplifypara")}
                </div>
                <img src={image} alt="" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                <div className="loginScreen-Box2">
                  <img src={Logo} alt="" />
                </div>
                <div className="login_head">
                  {(t as any)("websitelogin.welcome")}
                </div>
                <div className="login_subhead">
                  {(t as any)("websitelogin.pleaselogin")}
                </div>
                <Form
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <label className="formLabel">
                    {(t as any)("websitelogin.emailorstaff")}
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email or username!",
                      },
                    ]}
                  >
                    <Input className="login_input" size="large" />
                  </Form.Item>
                  <label className="formLabel">
                    {(t as any)("websitelogin.password")}
                  </label>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password className="login_input" size="large" />
                  </Form.Item>
                  <div className="login_checkbox">
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox>
                        {(t as any)("websitelogin.rememberme")}
                      </Checkbox>
                    </Form.Item>
                    <div>
                      <div
                        onClick={() => navigate("/LoginHelp#")}
                        style={{ color: "#ffbc49", cursor: "pointer" }}
                      >
                        {(t as any)("websitelogin.forgetpassword")}
                      </div>
                    </div>
                  </div>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      className="login_btn"
                    >
                      {(t as any)("websitecontact.submit")}
                    </Button>
                  </Form.Item>
                </Form>
                <div className="footer_text">
                  {(t as any)("websitelogin.donthaveaccount")}{" "}
                  <div
                    style={{ color: "#ffbc49" }}
                    onClick={() => navigate("/signup")}
                  >
                    {" "}
                    &nbsp; {(t as any)("websiteheader.signup")}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </ConfigProvider>
  );
}

export default LoginScreen;
