import { Button, ConfigProvider, Form, Input, notification } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import image from "../../assets/images/forget-password.png";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from "../../component/header";
import { FORGETPASSWORD, GETBASEURL } from "../../utils/apiCalls";
import "./styles.scss";

function ForgetScreen() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true)
      let endpoint = "base/active/email/" + values.email;
      const response: any = await GETBASEURL(endpoint, {});
      if (response.status) {
        let url = response?.data?.baseUrl + 'contactMaster/forgotPassword';
        var loginRes: any = await FORGETPASSWORD(url, {
          ...values,
        });
        if (loginRes.status) {
          notification.success({
            message: "Success",
            description: <div>Mail send success. Return to the <u style={{ color: '#ffbc49', cursor: 'pointer' }} onClick={() => navigate("/signup")}>Login</u> screen</div>,
          });
          setIsLoading(false);
        } else {
          notification.error({
            message: "Failed",
            description: loginRes?.message,
          });
          setIsLoading(false);
        }
      } else {
        notification.error({
          message: "Server Error",
          description: response.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Server Error",
        description: "Failed to login,Please try again later",
      });
      setIsLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#18a762",
          fontFamily: "Regular",
          borderRadius: 4,
          colorTextPlaceholder: "#888c99",
        },
      }}
    >
      <div>
        <Header />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="d-none  d-lg-flex justify-content-center align-items-center">
              <div className="login_box" >
                <p>Reset Password</p>
                <div className="login_text">Enter your email to receive a password reset link, then follow the instructions in the email to reset your password.</div>
                <img src={image} alt="" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                <div className="loginScreen-Box2">
                  <img src={Logo} alt="" />
                </div>
                <div className="login_head">Forgot Password</div>
                <div className="login_subhead">We will send you an email with instructions on how to reset your password.</div>
                <Form
                  style={{ width: '100%' }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <label className="formLabel">Email</label>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email or username!' }]}
                  >
                    <Input className="login_input" size="large" placeholder="name@example.com" />
                  </Form.Item>
                  <Form.Item >
                    <Button
                      loading={isLoading}
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      className="login_btn"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div className="footer_text">Return to the login screen. <u style={{ color: '#ffbc49', cursor: 'pointer' }} onClick={() => navigate("/signup")}>Login</u></div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    </ConfigProvider>
  )
}

export default ForgetScreen