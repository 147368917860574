import { Button, Form, Modal, Spin, notification } from "antd";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Cropper, { ReactCropperElement } from "react-cropper";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../redux/slices/userSlice";
import { COMPRESS_IMAGE, PUT } from "../../utils/apiCalls";
import PickImage from "./components/pickImage";
import "./styles.scss";

const ImagePicker = (props: any) => {
  const { user } = useSelector((state: any) => state?.User)
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>({});
  const type = props?.type || "update";
  const fileInputRef = useRef(null);

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  // const [aspectRatio, setAspectRatio] = useState(1 / 1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type == update) {
      form.setFieldsValue({
        image: props?.data?.companyInfo?.bimage,
      });
    } else {
      form.resetFields();
    }
  }, [props?.data, type]);

  const handleCropAndSubmit = async () => {
    setIsLoading(true);

    try {
      let imageUrl = props?.data?.image || props?.data?.companyInfo?.bimage;

      if (cropperRef.current) {
        const canvas: HTMLCanvasElement | null =
          cropperRef.current.cropper.getCroppedCanvas();

        if (canvas) {
          const croppedData = canvas.toDataURL("image/jpeg");

          const ImageBlob = await fetch(croppedData).then((r) => r.blob());
          const name = moment(new Date()).unix();
          const file = new File([ImageBlob], name + "N.jpg");
          imageUrl = await COMPRESS_IMAGE(file);
        }
      }

      const obj = {
        [props.image || 'image']: imageUrl?.url,
      };
      const response: any = await props.onFinish(obj)
      // const response: any = await PUT('contactMaster/update/' + user?.staff.id, obj);

      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${type == update ? "Updated" : "Added"}`,
        });
        form.resetFields();
        props?.modalClose();
        let obj = {
          ...response?.data,
          isStaff: user?.isStaff,
          staff: user?.staff,
        }
        props?.refreshData();
        setImage({});
        setCropModalOpen(false);
      } else {
        Notifications["error"]({
          message: `Failed to ${type == update ? "Update" : "Add New item"}`,
          description: response?.message,
        });
      }
    } catch (err: any) {
      console.error('------------ err ', err)
      Notifications["error"]({
        message: `Failed to ${type == update ? "Update" : "Add New item"}`,
        description: err.message,
      });
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title="Add New Photo"
      open={props?.open}
      okText="Save"
      centered
      width={500}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        props?.modalClose();
        setImage({});
        setCropModalOpen(false);
      }}
    >
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
      >
        {cropModalOpen ? (
          <div className="mt-2">
            <Spin spinning={isLoading} size="large">
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  const selectedFile = e.target.files?.[0];
                  if (selectedFile) {
                    setImage({
                      file: selectedFile,
                      url: URL.createObjectURL(selectedFile),
                    });
                    setCropModalOpen(true);
                  }
                }}
              />
              <Cropper
                ref={cropperRef as React.RefObject<ReactCropperElement>}
                src={image?.url}
                style={{ height: 400, width: "100%" }}
                // aspectRatio={aspectRatio}
                guides={true}
              />
              <br />
              <Row>
                <Col sm={6}>
                  <Button
                    style={{ marginBottom: '10px' }}
                    block
                    size="large"
                    onClick={() => {
                      if (fileInputRef.current) {
                        (fileInputRef.current as any).click();
                      }
                    }}
                  >
                    Choose Another Image
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    block
                    size="large"
                    type="primary"
                    onClick={() => handleCropAndSubmit()}
                  >Upload Image</Button>
                </Col>
              </Row>
            </Spin>
          </div>
        ) : (
          <>
            <Form.Item
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please Upload image",
                },
              ]}
            >
              <PickImage
                onChange={(file: any) => {
                  setImage(file);
                  setCropModalOpen(true);
                }}
                fileURL={
                  image?.url
                    ? image.url
                    : type == update
                      ? props?.data?.companyInfo?.bimage
                      : null
                }
              />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <Button
                  style={{ marginBottom: '10px' }}
                  block
                  danger
                  size="large"
                  onClick={() => {
                    props?.modalClose();
                    setImage({});
                  }}
                >
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  size="large"
                  block
                  type="primary"
                  loading={isLoading}
                >
                  {type == "update" ? "Update" : "Add"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ImagePicker;
