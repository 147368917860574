import { CiImageOff } from "react-icons/ci";
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md";

function ListItem(props: any) {
  let totalRate = props?.item?.rate - props?.item?.vatamt

  return (
    <>
      <div className="order-ProductItem d-none d-lg-block" onClick={() => {
        props.onSelect(props?.item);
      }}>
        <div>
          {props?.item?.pimage ? (
            <img
              src={props?.item?.pimage}
              className="ProductItemImg"
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CiImageOff color="#d3d3d3" size={40} />
            </div>
          )}
        </div>
        <div className="order-ProductItemBox">
          <div className="order-ProductItemtxt1">
            {props?.item?.idescription} ({props?.item?.icode})
          </div>
          <div className="order-ProductItemtxt2">
            {props?.currencycode} {props?.item?.includevat == '1.00' ?
              totalRate.toFixed(2) :
              props?.item?.rate}
          </div>
          <div className="order-ProductItemtxt3">
            {props.type == 'Stock' ? (
              <div>{props?.item?.remainingStock} {props?.item?.unitDetails?.unit} left</div>
            ) : (<div>.</div>)}
            <div>
              {props?.selectedItem?.find((slectedProduct: any) => Number(slectedProduct.id) === (props?.item.id)) ?
                <MdOutlineRadioButtonChecked size={20} color="green" /> :
                <MdOutlineRadioButtonUnchecked color="grey" size={20} />}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between d-lg-none ProductItemBox" onClick={() => {
        props.onSelect(props?.item);
      }}>
        <div style={{ width: '100px', height: '100px', position: 'relative', border: '1px solid #d3d3d3', borderRadius: '8px' }}>
          {props?.item?.pimage ? (
            <img
              src={props?.item?.pimage}
              className="ProductItemImg2"
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CiImageOff color="#d3d3d3" size={40} />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between" style={{ width: '75%', marginLeft: '8px' }}>
          <div>
            <div className="ProductItemtxt1">
              {props?.item?.idescription}
            </div>
            <div className="ProductItemtxt2">
              {props?.currencycode} {props?.item?.includevat == '1.00' ?
                totalRate.toFixed(2) :
                props?.item?.costprice || props?.item?.rate}
            </div>
            <div className="ProductItemtxt3">{props?.item?.remainingStock || 0} {props?.item?.unitDetails?.unit} left</div>
          </div>
          <div>
            {props?.selectedItem?.find((slectedProduct: any) => Number(slectedProduct.id) === (props?.item.id)) ?
              <MdOutlineRadioButtonChecked size={20} color="green" /> :
              <MdOutlineRadioButtonUnchecked color="grey" size={20} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default ListItem;
