import { Card } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import { CgArrowsExchange } from "react-icons/cg";
import { TbBrandDaysCounter } from "react-icons/tb";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import image from '../../../assets/images/counter-img.png';
import OuterPageHeader from '../../../component/OuterHeader/OuterPageHeader';
import Header from '../../../component/retailHeader/header';
import { useTranslation } from 'react-i18next';

function ViewCounter() {
    const { t } = useTranslation();

    const location = useLocation();
    const state: any = location.state;
    const { user } = useSelector((state: any) => state.User);

    return (
        <div className='view-counter'>
            <Header isNotSerach={true} />
            <br />
            <Container>
                <OuterPageHeader title={(t as any)("counter.text_6")} />
                <br />
                <Card>
                    <Row>
                        <Col lg={6} className='d-flex justify-content-center align-items-center'>
                            <img className='image' src={image} />
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col xs={6}>
                                    <div className='report-card'>
                                        <div className='report-text'>{state?.shiftlist?.length}</div>
                                        <div className='report-text2'>{(t as any)("counter.text_7")}</div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className='report-card'>
                                        <div className='report-text'>{user?.companyInfo?.countryInfo?.symbol} {state?.balance}</div>
                                        <div className='report-text2'>{(t as any)("counter.text_8")}</div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    {state?.shiftlist?.map((item: any) => {
                                        return (
                                            <div className='list-card'>
                                                <div className='card-icone'>
                                                    <TbBrandDaysCounter color={'#36b372'} size={35} />
                                                </div>
                                                <div>
                                                    <div className='report-text2'>{item.fromtime} <CgArrowsExchange size={25} /> {item.totime}</div>
                                                    <div className='list-text'>{item.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    )
}

export default ViewCounter