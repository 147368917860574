import { createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
const removeAProduct = (products: any, data: any) => {
  const findInd = products.findIndex((item: any) => item.id === data);
  if (findInd !== -1) {
    products.splice(findInd, 1);
  }
  return products;
};
const addProduct = (products: any, data: any) => {
  let product = products;
  const findInd = products.findIndex((item: any) => item.id === data.id);
  if (findInd < 0) {
    if (Number(product.stock) == Number(product?.quantity_no)) {
      notification.error({ message: "You have no stock in this product" });
    } else {
      product = [...product, data];
    }
  }
  return product;
};

const addCartProduct = (products: any, data: any) => {
  return [...data];
};

function addQuantity(products: any, data: any) {
  let indexNo = products.findIndex((item: any) => item.id === data);
  if (
    Number(products[indexNo].stock) == Number(products[indexNo]?.quantity_no)
  ) {
    notification.error({
      message: "This is the maximum quantity for this product",
    });
  } else {
    products[indexNo].quantity_no = Number(products[indexNo]?.quantity_no) + 1;
  }
  return products;
}
function minusQuantity(products: any, data: any) {
  let indexNo = products.findIndex((item: any) => item.id === data);
  if (products[indexNo]?.quantity_no > 1) {
    products[indexNo].quantity_no = Number(products[indexNo]?.quantity_no) - 1;
  }
  return products;
}
function enterQuantity(products: any, data: any) {
  let indexNo = products.findIndex((item: any) => item.id === data.id);
  if (Number(products[indexNo].stock) < Number(data.quantity)) {
    products[indexNo].quantity_no = Number(products[indexNo].stock);
    notification.error({
      message: "This is the maximum quantity for this product",
    });
  } else {
    products[indexNo].quantity_no = data.quantity;
  }
  return products;
}

const addHoldProducts = (products: any, data: any) => {
  let product = products;
  if (products?.length) {
    product = [data, ...product];
  } else {
    product = [data];
  }
  return product;
};

const removeHoldProducts = (products: any, data: any) => {
  let product = products;
  product.splice(data, 1);
  return product;
};

//-----Order-----------------------------
//------- Add Order -------------
const addOrder = (order: any, data: any) => {
  let orders = order;
  const findInd = order?.findIndex((item: any) => item.id === data.id);
  if (findInd < 0) {
    orders = [...orders, data];
  } else {
    orders = [data];
  }
  return orders;
};

//------- Plus Order Quantity -------------
function addOrderQuantity(order: any, data: any) {
  let indexNo = order.findIndex((item: any) => item.id === data);
  if (
    Number(order[indexNo].remainingStock) == Number(order[indexNo]?.quantity_no)
  ) {
    notification.error({
      message: "This is the maximum quantity for this product",
    });
  } else {
    order[indexNo].quantity_no = Number(order[indexNo]?.quantity_no) + 1;
  }
  return order;
}

//------- Minus Order Quantity -------------
function minusOrder(order: any, data: any) {
  let indexNo = order.findIndex((item: any) => item.id === data);
  if (order[indexNo]?.quantity_no > 1) {
    order[indexNo].quantity_no = Number(order[indexNo]?.quantity_no) - 1;
  }
  return order;
}

//------- Enter Order Quantity -------------
function enterOrder(order: any, data: any) {
  let indexNo = order.findIndex((item: any) => item.id === data.id);
  if (Number(order[indexNo].stock) < Number(data.quantity)) {
    order[indexNo].quantity_no = Number(order[indexNo].stock);
    notification.error({
      message: "This is the maximum quantity for this product",
    });
  } else {
    order[indexNo].quantity_no = data.quantity;
  }
  return order;
}

function enterImeis(order: any, data: any) {
  let indexNo = order.findIndex((item: any) => item.id === data?.id);
  order[indexNo].imei = data?.data;
  return order;
}

//------- Remove Order Item -------------
const removeOrder = (order: any, data: any) => {
  const findInd = order.findIndex((item: any) => item.id === data);
  if (findInd !== -1) {
    order.splice(findInd, 1);
  }
  return order;
};
let initialState: any = {
  products: [],
  holdProducts: [],
  order: [],
  counter: {},
  SoleTrader:false,
};
const retailExpressSlice = createSlice({
  name: "retailExpress",
  initialState: initialState,

  reducers: {
    addProducts: (state: any, action: any) => {
      state.products = addProduct(state.products, action.payload);
    },
    addCartProducts: (state: any, action: any) => {
      state.products = addCartProduct(state.products, action.payload);
    },
    addProductQuantity: (state: any, action: any) => {
      state.products = addQuantity(state.products, action.payload);
    },
    addImeis: (state: any, action: any) => {
      state.products = enterImeis(state.products, action.payload);
    },
    minusProductQuantity: (state: any, action: any) => {
      state.products = minusQuantity(state.products, action.payload);
    },
    enterProductQuantity: (state: any, action: any) => {
      state.products = enterQuantity(state.products, action.payload);
    },
    removeProducts: (state, action) => {
      state.products = removeAProduct(state.products, action.payload);
    },
    clearProduct: (state, action) => {
      state.products = [];
    },

    addHoldProduct: (state, action) => {
      state.holdProducts = addHoldProducts(state.holdProducts, action.payload);
    },
    clearHoldProducts: (state) => {
      state.holdProducts = [];
    },
    removeHoldProduct: (state, action) => {
      state.holdProducts = removeHoldProducts(
        state.holdProducts,
        action.payload
      );
    },
    addCounter: (state, action) => {
      state.counter = action.payload;
    },
    clearCounter: (state, action) => {
      state.counter = {};
    },
    isSoleTrader: (state, action) => {
      state.SoleTrader = action.payload;
    },

    //orede
    addOrders: (state: any, action: any) => {
      state.order = addOrder(state.order, action.payload);
    },
    addOrdersQuantity: (state: any, action: any) => {
      state.order = addOrderQuantity(state.order, action.payload);
    },
    minusOrderQuantity: (state: any, action: any) => {
      state.order = minusOrder(state.order, action.payload);
    },
    enterOrderQuantity: (state: any, action: any) => {
      state.order = enterOrder(state.order, action.payload);
    },

    removeOrders: (state, action) => {
      state.order = removeOrder(state.order, action.payload);
    },
    clearOrder: (state) => {
      state.order = [];
    },
  },
});

export const {
  addProducts,
  removeProducts,
  clearProduct,
  addProductQuantity,
  minusProductQuantity,
  clearHoldProducts,
  addCartProducts,
  removeHoldProduct,
  addHoldProduct,
  addCounter,
  clearCounter,
  isSoleTrader,
  enterProductQuantity,
  //Order
  addOrders,
  addOrdersQuantity,
  minusOrderQuantity,
  addImeis,
  enterOrderQuantity,
  removeOrders,
  clearOrder,
} = retailExpressSlice.actions;
export default retailExpressSlice.reducer;



