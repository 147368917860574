import { Button, Checkbox, Collapse, DatePicker, Form, Input, InputNumber, Select, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addCounter, clearCounter } from '../../redux/slices/retailExpress';
import ClosePayment from '../../retailExpress/invoice/components/closePayment';
import { GET, POST } from '../../utils/apiCalls';
import './styles.scss';

function CounterModal({ counterList, counterSearch, onClose, paymentModal, counterDetails, setClockIn, logOut, logOutOpen }: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [btLoding, setBtLoding] = useState(false);
    const [shiftList, setShiftList] = useState<any>([]);
    const [denomination, setDenomination] = useState<any>([]);
    const [counterData, setCounterData] = useState<any>([]);
    const [shortAmount, setShortAmount] = useState<any>(0);
    const [short, setShort] = useState<any>(0);
    const [shiftReport, setShiftReport] = useState<any>();
    const [close, setClose] = useState<any>(true);
    const [closePaymentModal, setClosePaymentModal] = useState(false);
    const [bankList, setBankList] = useState<any>()

    useEffect(() => {
        form.setFieldsValue({
            sdate: dayjs(new Date()),
        })
        if (counter?.id || counterDetails?.id) {
            form.setFieldsValue({
                counter_id: counter?.counter_id?.toString() || counterDetails?.counter_id?.toString(),
                shift: counter?.shift_type || counterDetails?.shift_type?.toString(),
                sdate: counter?.sdate ? dayjs(counter?.sdate, "YYYY-MM-DD") : dayjs(dayjs(counterDetails?.created_at, "YYYY-MM-DD")),
                close: true
            })
            const shift = counterList?.find((find: any) => {
                return find.id == counter?.counter_id || counterDetails?.id
            })
            setDenomination(shift?.denomination?.banknotes || shift?.denomination?.denomination)
            form.setFieldValue('floatbalance', shift?.balance);
        }
        if (counterList.length == 1) {
            const counterData = counterList[0]
            setShiftList(counterData?.shiftlist)
            form.setFieldsValue({
                counter_id: counterData.id.toString(),
                shift: counterData?.shiftlist.length == 1 ? counterData?.shiftlist[0]?.name.toString() : ''
            })
        }
    }, [])
    const onFinish = async (val: any) => {
        try {
            setBtLoding(true)
            if (val.close) {
                pymentCreateToLedeger(val)
            }
            let notes: any = []
            val?.denomination?.forEach((note: any) => {
                let shiftObj = {
                    denomination: note?.denomination,
                    count: note?.count
                }
                notes.push(shiftObj)
            })
            let dinominationsObj = {
                denomination: notes,
                total_balance: val.balance,
                time: dayjs().format("hh:mm A"),
            }

            let openObj = {
                open_denomination: dinominationsObj,
                counter_id: Number(val?.counter_id),
                sdate: val?.sdate,
                adminid: user?.id,
                balance: val?.balance,
                shift_type: val?.shift,
                staffid: user?.staff?.id,
                companyid: user?.staff?.companyid,
            };

            let closeObj = {
                [!counter?.id ? counterDetails?.id ? 'open_denomination' : 'close_denomination' : 'close_denomination']: dinominationsObj,
                balance: val?.balance,
                id: counter?.id || counterDetails?.id,
                counter_id: Number(val?.counter_id),
                companyid: user?.staff?.companyid,
            };

            let url = counter?.id || counterDetails?.id ? `counter_details/add/closeshift` : `counter_details/add/openshift`;
            let obj = counter?.id || counterDetails?.id ? closeObj : openObj
            const response: any = await POST(url, obj)
            if (response.status) {
                if (counter?.id) {
                    setBtLoding(false)
                    if (logOutOpen) {
                        await logOut()
                        navigate("/login");
                    } else {
                        dispatch(clearCounter({}))
                    }
                    onClose()
                } else {
                    dispatch(addCounter(response.data));
                    setBtLoding(false)
                    onClose()
                    await paymentModal()
                }
                notification.success({
                    message: "success",
                    description: counter?.id ? `Success fully Shift close` : `Success fully Shift Open`
                })
            } else {
                notification.error({
                    message: "Failed", description: counter?.id ? `Failed Shift close` : response.message
                })
                setBtLoding(false)
            }
        } catch (error) {
            setBtLoding(false)
            console.error(error, '错误信)')
        }
    }
    const onValueChange = (_: any, val: any) => {
        if (_?.close == false || _?.close == true) {
            setClose(_?.close)
        }
        const calculateTotal = (items: any[]) => {
            return items?.reduce((acc: number, item: any) => {
                const denomination = Number(item?.denomination) || 0;
                const count = Number(item?.count) || 0;
                return acc + (denomination * count);
            }, 0);
        };
        if (val && (val.denomination?.length)) {
            const notesTotal = calculateTotal(val?.denomination || []);
            const balance = notesTotal
            const value = Number(short) - Number(notesTotal)
            setShortAmount(value)

            form.setFieldValue('balance', balance);
        }
        if (_.counter_id) {
            const shift = counterList.find((find: any) => find.id == _.counter_id)
            setShiftList(shift?.shiftlist)
            setDenomination(shift?.denomination?.banknotes || shift?.denomination?.denomination)
            form.setFieldValue('floatbalance', shift.balance);
        }
    };

    const fetchCounter = async () => {
        try {
            let obj = {
                staffid: user?.staff?.id,
                adminid: user.id,
                companyid: user?.staff?.companyid,
                shiftid: counter?.id,
            }
            let url = `StaffTransactions/shift-report`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setShiftReport(response.data)
                setShortAmount(Number(response.data.balance) + Number(response.data.total_sale_rate))
                setShort(Number(response.data.balance) + Number(response.data.total_sale_rate))
                setCounterData(
                    [
                        {
                            value: Number(response.data.sale_row_count) || 0.00,
                            message: (t as any)("denomination.text_3"),
                            color: 'green'
                        },
                        {
                            value: user?.companyInfo?.countryInfo?.symbol + Number(response.data.total_sale_rate).toFixed(2) || 0.00,
                            message: (t as any)("denomination.text_4"),
                            color: 'green'
                        },
                        {
                            value: user?.companyInfo?.countryInfo?.symbol + Number(response.data.balance).toFixed(2) || 0.00,
                            message: (t as any)("denomination.text_5"),
                            color: 'green'
                        },
                        {
                            value: Number(response.data.open_invoices) || 0.00,
                            message: (t as any)("denomination.text_6"),
                            color: 'green'
                        },
                    ]
                );
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }
    const fetchBankList = async () => {
        try {
            let bank_list_url = "account_master/getBankList/" + user?.id + '/' + user?.staff?.companyid;
            const { data }: any = await GET(bank_list_url, null);
            setBankList(data?.list);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (counter?.id) {
            fetchCounter()
            fetchBankList()
        }
    }, [])

    const pymentCreateToLedeger = async (values: any) => {
        try {
            let url = "StaffTransactions/add/pymentcreatetoledeger";
            let obj = {
                staffTransactions: shiftReport.open_invoice_list,
                bankid: values?.ledger,
                paidmethod: values?.paidmethod,
                date: new Date(),
                companyid: user?.companyid
            }
            const response: any = await POST(url, obj)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div>
            <Form
                onFinish={onFinish}
                onValuesChange={onValueChange}
                form={form}
            >
                <Row>
                    <Col className="Table-Txt" md={12}>
                        {counter?.id ? (t as any)("denomination.text") : (t as any)("denomination.text_1")}
                    </Col>
                    <br />
                    <br />
                    <hr />
                    {counter?.id ? (
                        <Col xs={12}>
                            <Row className='mb-3'>
                                {counterData?.map((item: any) => {
                                    return (
                                        <Col xs={6} md={4} lg={3}>
                                            <div className='top-card'>
                                                <div className='text' style={{ color: item?.color }}>{item?.value}</div>
                                                <div className='text2'>{item?.message}</div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    ) : null}
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">{(t as any)("denomination.label")}</label>
                            <Form.Item
                                name="counter_id"
                                rules={[
                                    {
                                        required: true,
                                        message: (t as any)("denomination.required"),
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onSearch={(val) => counterSearch(val)}
                                    showSearch
                                    filterOption={false}
                                    size="large"
                                    disabled={counter?.id || counterDetails?.id}
                                >
                                    {counterList?.map((item: any) => (
                                        <Select.Option key={item?.id}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="formItem">
                            <label className="formLabel">{(t as any)("denomination.label_1")}</label>
                            <Form.Item name="shift"
                                rules={[
                                    {
                                        required: true,
                                        message: (t as any)("denomination.required_1"),
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    size="large"
                                    disabled={counter?.id || counterDetails?.id}
                                >
                                    {shiftList?.map((item: any) => (
                                        <Select.Option key={item?.name}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">{(t as any)("denomination.label_2")}</label>
                            <Form.Item
                                name="sdate"
                                rules={[
                                    {
                                        required: true,
                                        message: (t as any)("denomination.required_3"),
                                    },
                                ]}
                            >
                                <DatePicker disabled format="YYYY-MM-DD" style={{ width: "100%" }} size="large" />
                            </Form.Item>
                        </div>
                        {denomination?.length ? (
                            <div className="formItem">
                                <label className="formLabel">{(t as any)("denomination.label_3")}</label>
                                <Form.Item
                                    name="floatbalance"
                                >
                                    <Input readOnly type='number' size="large" />
                                </Form.Item>
                            </div>
                        ) : null}
                    </Col>

                    {denomination?.length ? (
                        <Col>
                            <Collapse
                                accordion
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => (
                                    <FaChevronRight
                                        style={{
                                            transition: 'transform 0.3s ease',
                                            transform: `rotate(${isActive ? 90 : 0}deg)`
                                        }}
                                    />
                                )}
                            >
                                <Collapse.Panel header={<div className='RetailExpress-ListItemtxt'>{(t as any)("denomination.label_5")}</div>} key="1">
                                    <div className='denomination'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{(t as any)("denomination.text_7")}</th>
                                                    <th>{(t as any)("denomination.text_8")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {denomination && denomination?.map((item: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>{item?.denomination}</td>
                                                        <td>{item?.count}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    ) : null}
                    <Col md={12}>
                        <div className="formItem">
                            <Form.List name="denomination">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div className='denominationForm'>
                                                <div className="denominationInput">
                                                    <label className="formLabel">{(t as any)("denomination.label_5")}</label>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'denomination']}
                                                        rules={[{ required: true, message: (t as any)("denomination.required_4") }]}
                                                    >
                                                        <Input type='number' placeholder={(t as any)("denomination.placeholder")} size="large" />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <label className="formLabel">{(t as any)("denomination.label_6")}</label>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'count']}
                                                        rules={[{ required: true, message: (t as any)("denomination.required_5") }]}
                                                    >
                                                        <InputNumber controls={false} placeholder={(t as any)("denomination.placeholder_1")} size="large" />
                                                    </Form.Item>
                                                </div>
                                                <RiDeleteBin4Line cursor='pointer' color='red' size={18} onClick={() => remove(name)} />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" size="large" onClick={() => add()} block icon={<FiPlus size={24} />}>
                                                {(t as any)("denomination.label_7")}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Col>
                    {counter?.id ? (
                        <div className='short-balance'>{(t as any)("denomination.label_8")} : {user?.companyInfo?.countryInfo?.symbol}  {shortAmount.toFixed(2) || 0.00}</div>
                    ) : null}
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">{(t as any)("denomination.label_9")}</label>
                            <Form.Item
                                name="balance"
                            >
                                <Input readOnly size="large" />
                            </Form.Item>
                        </div>
                        {counter?.id && close ? (
                            <div className="formItem">
                                <label className="formLabel">{(t as any)("denomination.label_10")}</label>
                                <Form.Item
                                    name="ledger"
                                    rules={[
                                        {
                                            required: true,
                                            message: (t as any)("denomination.required_6"),
                                        },
                                    ]}
                                >
                                    <Select
                                        size="large"
                                        placeholder={(t as any)("denomination.placeholder_2")}
                                        filterOption={false}
                                    >
                                        {bankList?.map((item: any) =>
                                            <Select.Option value={item?.id} key={item?.id}>
                                                {item?.laccount}
                                            </Select.Option>
                                        )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        ) : null}
                    </Col>
                    {counter?.id ? (
                        <Col md={6}>
                            <div className="formItem" style={{ marginBottom: '31px' }}>
                                <label className="formLabel">{(t as any)("denomination.label_11")}</label>
                                <Form.Item
                                    name="close"
                                    valuePropName="checked"
                                >
                                    <Checkbox >{close ? (t as any)("denomination.label_13") : (t as any)("denomination.label_14")}</Checkbox>
                                </Form.Item>
                            </div>
                            {close ? (
                                <div className="formItem">
                                    <label className="formLabel">{(t as any)("denomination.label_12")}</label>
                                    <Form.Item
                                        name="paidmethod"
                                        rules={[
                                            {
                                                required: true,
                                                message: (t as any)("denomination.required_7"),
                                            },
                                        ]}
                                    >
                                        <Select size="large" placeholder={(t as any)("denomination.placeholder_3")}>
                                            <Select.Option key={"cash"}>{(t as any)("denomination.label_12.1")}</Select.Option>
                                            <Select.Option key={"cheque"}>{(t as any)("denomination.label_12.2")}</Select.Option>
                                            <Select.Option key={"electronic"}>{(t as any)("denomination.label_12.3")}</Select.Option>
                                            <Select.Option key={"card"}>{(t as any)("denomination.label_12.4")}</Select.Option>
                                            <Select.Option key={"paypal"}>{(t as any)("denomination.label_12.5")}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : null}
                        </Col>
                    ) : null}
                    <Col md={counter?.id ? 8 : 4}></Col>
                    <Col md={6}>
                        <Button
                            block
                            size="large"
                            onClick={() => {
                                onClose()
                                setClockIn()
                            }}
                            style={{ marginBottom: '5px' }}
                        >
                            {(t as any)("denomination.text_9")}
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            loading={btLoding}
                            style={{ marginBottom: '5px' }}
                        >
                            {(t as any)("denomination.text_10")}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {
                closePaymentModal &&
                <ClosePayment
                    isOpen={closePaymentModal}
                    setModalOpen={() => {
                        setClosePaymentModal(false)
                    }}
                    selectedRow={shiftReport.open_invoice_list}
                    bankList={bankList}
                />
            }
        </div>
    )
}

export default CounterModal