import { Button, DatePicker, Form, Input, InputNumber, Select, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DynamicIcon from '../../../navigation/drower/dynamicIcon';
import { addCounter } from '../../../redux/slices/retailExpress';
import { POST } from '../../../utils/apiCalls';
import '../styles.scss';

function CounterDetails({ counterList, counterSearch, counterDetails, loding }: any) {
    const [form] = Form.useForm();
    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [btEnable, setBtEnable] = useState(true);
    const [btLoding, setBtLoding] = useState(false);
    const [shiftList, setShiftList] = useState<any>([]);
    const [denomination, setDenomination] = useState<any>([]);
    const [counterData, setCounterData] = useState<any>([]);

    useEffect(() => {
        form.setFieldsValue({
            sdate: dayjs(new Date()),
        })
        if (counter?.id || counterDetails?.id) {
            const shift = counterList?.find((find: any) => {
                return find.id == counter?.counter_id || counterDetails?.id
            })
            form.setFieldsValue({
                counter_id: counter?.counter_id?.toString() || counterDetails?.counter_id?.toString(),
                shift: counter?.shift_type || counterDetails?.shift_type?.toString(),
                sdate: counter?.sdate ? dayjs(counter?.sdate, "YYYY-MM-DD") : dayjs(dayjs(counterDetails?.created_at, "YYYY-MM-DD")),
                denomination: shift?.denomination.denomination || shift?.denomination?.banknotes,
                floatbalance: shift?.balance,
                balance: shift?.balance,
            })
            setDenomination(shift?.denomination?.banknotes || shift?.denomination?.denomination)
        }
    }, [])
    const onFinish = async (val: any) => {
        try {
            setBtLoding(true)
            let notes: any = []
            val?.denomination?.forEach((note: any) => {
                let shiftObj = {
                    denomination: note?.denomination,
                    count: note?.count
                }
                notes.push(shiftObj)
            })
            let dinominationsObj = {
                denomination: notes,
                total_balance: val.balance,
                time: dayjs().format("hh:mm A"),
            }

            let closeObj = {
                open_denomination: dinominationsObj,
                balance: val?.balance,
                id: counter?.id || counterDetails?.id,
                counter_id: Number(val?.counter_id),
                companyid: user?.staff?.companyid,
            };

            let url = `counter_details/add/closeshift`;
            let obj = closeObj
            const response: any = await POST(url, obj)
            if (response.status) {
                dispatch(addCounter(response.data));
                setBtLoding(false)
                notification.success({
                    message: "success",
                    description: `Success fully update Shift Open`
                })
                await loding()
            } else {
                notification.error({
                    message: "Failed", description: response.message
                })
                setBtLoding(false)
            }
        } catch (error) {
            setBtLoding(false)
            console.error(error, '错误信)')
        }
    }
    const onValueChange = (_: any, val: any) => {
        const calculateTotal = (items: any[]) => {
            return items.reduce((acc: number, item: any) => {
                const denomination = Number(item?.denomination) || 0;
                const count = Number(item?.count) || 0;
                return acc + (denomination * count);
            }, 0);
        };
        if (val && (val.denomination?.length)) {
            const notesTotal = calculateTotal(val?.denomination || []);
            const balance = notesTotal
            setBtEnable(balance != 0 ? false : true)
            form.setFieldValue('balance', balance);
        }
        if (_.counter_id) {
            const shift = counterList.find((find: any) => find.id == _.counter_id)
            setShiftList(shift?.shiftlist)
            setDenomination(shift?.denomination?.banknotes || shift?.denomination?.denomination)
            form.setFieldValue('floatbalance', shift.balance);
        }
    };

    const fetchCounter = async () => {
        try {
            let obj = {
                staffid: user?.staff?.id,
                adminid: user?.id,
                companyid: user?.staff?.companyid,
                shiftid: counter?.id,
            }
            let url = `StaffTransactions/shift-report`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setCounterData(
                    [
                        {
                            value: response.data.balance,
                            icon: 'FaBalanceScaleRight',
                            message: 'CONTER BALANCE',
                            color: 'green'
                        },
                        {
                            value: response.data.row_count,
                            icon: 'TbFileInvoice',
                            message: 'SALE INVOICES',
                            color: 'green'
                        },
                        {
                            value: Number(response.data.total_rate),
                            icon: 'TbCurrency',
                            message: 'INVOICE TOTAL',
                            color: 'green'
                        },
                    ]
                );
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    useEffect(() => {
        if (counter?.id) {
            fetchCounter()
        }
    }, [])
    return (
        <div>
            <Form
                onFinish={onFinish}
                onValuesChange={onValueChange}
                form={form}
            >
                <Row>
                    <Row>
                        {counterData?.map((item: any) => {
                            return (
                                <Col>
                                    <div className='counterCatdBox'>
                                        <DynamicIcon name={item?.icon} size={30} />
                                        <div className='text' style={{ color: item?.color }}>{item.value.toFixed(2)}</div>
                                        <div className='text2'>{item?.message}</div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">COUNTER</label>
                            <Form.Item
                                name="counter_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select a ledger",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onSearch={(val) => counterSearch(val)}
                                    showSearch
                                    filterOption={false}
                                    size="large"
                                    disabled
                                >
                                    {counterList?.map((item: any) => (
                                        <Select.Option key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="formItem">
                            <label className="formLabel">SHIFT</label>
                            <Form.Item name="shift"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select a Shift",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    size="large"
                                    disabled
                                >
                                    {shiftList?.map((item: any) => (
                                        <Select.Option key={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">DATE</label>
                            <Form.Item
                                name="sdate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter date",
                                    },
                                ]}
                            >
                                <DatePicker disabled format="YYYY-MM-DD" style={{ width: "100%" }} size="large" />
                            </Form.Item>
                        </div>
                        {denomination?.length ? (
                            <div className="formItem">
                                <label className="formLabel">CLOSE FLOAT CASH BALANCE</label>
                                <Form.Item
                                    name="floatbalance"
                                >
                                    <Input readOnly type='number' size="large" />
                                </Form.Item>
                            </div>
                        ) : null}
                    </Col>
                    <Col md={12}>
                        <div className="formItem">
                            <Form.List name="denomination">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div className='denominationForm'>
                                                <div className="denominationInput">
                                                    <label className="formLabel">DENOMINATION</label>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'denomination']}
                                                        rules={[{ required: true, message: 'Missing Denomination' }]}
                                                    >
                                                        <Input type='number' placeholder="Denomination" size="large" />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <label className="formLabel">COUNT</label>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'count']}
                                                        rules={[{ required: true, message: 'Missing Count' }]}
                                                    >
                                                        <InputNumber controls={false} placeholder="Count" size="large" />
                                                    </Form.Item>
                                                </div>
                                                <RiDeleteBin4Line color='red' size={18} onClick={() => remove(name)} />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" size="large" onClick={() => add()} block icon={<FiPlus size={24} />}>
                                                UPDATE OPEN DENOMINATION
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="formItem">
                            <label className="formLabel">FLOAT CASH BALANCE</label>
                            <Form.Item
                                name="balance"
                            >
                                <Input readOnly size="large" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            disabled={btEnable}
                            loading={btLoding}
                            style={{ marginBottom: '5px' }}
                        >
                            UPDATE
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CounterDetails