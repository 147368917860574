import { combineReducers } from "@reduxjs/toolkit";
import UserSlice from "./userSlice";
import retailExpress from "./retailExpress";
import paystackSlice from "./paystackSlice";
const Slices = combineReducers({
  User: UserSlice,
  retailExpress: retailExpress,
  paystack:paystackSlice,
});
export default Slices;
