import { LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, Spin, Upload, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Setings from '../../../component/settings';
import API from '../../../config/api';
import { GET, POST, POST2, PUT } from '../../../utils/apiCalls';
import { useTranslation } from "react-i18next";

function ServiceForm({ close, productCModal, getDatas, url, id }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const [form] = Form.useForm();

    const [category, setCategory] = useState([]);
    const [taxlist, setTaxlist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [image, setImage] = useState<any>();
    const [setingsModal, setSetingsModal] = useState<any>(false);
    const [input, setInput] = useState<any>([]);

    useEffect(() => {
        LoadCategory();
        loadTaxList();
        if (id) {
            lodeProduct()
        }
    }, []);
    const LoadCategory = async () => {
        let URL =
            API.PRODUCTCATEGORY_LIST_USER + `${user?.id}/${user?.companyInfo?.id}`;
        const data: any = await GET(URL, null);
        setCategory(data?.data);
    };

    const loadTaxList = async () => {
        try {
            let URL = API.TAX_MASTER + `list/${user?.id}/${user?.companyInfo?.id}`;
            const data: any = await GET(URL, null);
            setTaxlist(data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const lodeProduct = async () => {
        try {
            let URL = API.GET_PRODUCT_BY_ID + id;
            const data: any = await GET(URL, null);
            let product_data = data.data
            setImage(product_data.pimage)
            form.setFieldsValue({
                icode: product_data.icode,
                idescription: product_data.idescription,
                product_category: product_data.product_category,
                sale_price: product_data.rate,
                hsn_code: product_data.hsn_code,
                vat: product_data.vat,
                includevat: product_data.includevat == '0.00' ? false : true,
                vatamt: product_data.vatamt,
                total_price: product_data.includevat == '0.00' ? Number(product_data.rate) + Number(product_data.vatamt) : product_data.rate,
            })
        } catch (error) {
        }
    }

    const onFinish = async (val: any) => {
        setIsLoading(true)
        try {
            let obj = {
                itemtype: "Service",
                icode: val.icode,
                idescription: val?.idescription,
                saccount: 4000,
                price: 0,
                sp_price: val?.sale_price,
                c_price: 0,
                trade_price: 0,
                rate: val?.sale_price,
                notes: "",
                type: "Service",
                logintype: "user",
                paccount: 0,
                includevat: val.includevat ? 1 : 0,
                userid: user?.id,
                adminid: user?.id,
                vat: Number(val?.vat) || 0,
                vatamt: val?.vatamt,
                product_category: val?.product_category,
                existingstock: false,
                date: dayjs(new Date()),
                createdBy: user?.staff?.id,
                companyid: user?.staff?.companyid,
                hsn_code: user?.companyInfo?.country == 101 ? val.hsn_code : null,
                pimage: image,
            };
            let METHERD = id ? PUT : POST
            let res: any = await METHERD(url, obj)
            if (res.status) {
                notification.success({
                    message: id ? res.message : 'Service created successfully.',
                });
                form.resetFields();
                setImage('')
                setIsLoading(false)
                close()
                getDatas()
            } else {
                notification.error({
                    message: res.message,
                });
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            notification.error({
                message: 'Server Error Try again',
            });
            setIsLoading(false)
        }
    }

    const onUpload = async (info: any) => {
        const { file } = info;
        if (file.status !== "uploading") {
            await uploadLogo(file.originFileObj);
        }
    };

    const uploadLogo = async (imagedata: any) => {
        var formdata = new FormData();
        setLoading(true);
        formdata.append("productid", "create");
        formdata.append("file", imagedata, imagedata.name);
        let graphData_url = API.PRODUCTMASTER_IMAGE_UPLOADER;
        try {
            const { data }: any = await POST2(graphData_url, formdata);

            if (data?.location) {
                setLoading(false);
                setImage(data.location);
                notification.success({
                    message: " Image Uploaded",
                    description: "Your image has been uploaded successfully.",
                });
            }
        } catch (error) {
            // Handle error if necessary
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onValuesChange = async (val: any, arr: any) => {
        let rate = Number(arr.sale_price);
        let vatPercent = Number(arr.vat);
        let cost_price = Number(arr.c_price);
        let cost_price_with_vat;
        let totalPrice;
        if (rate && (vatPercent || Number(vatPercent) === 0)) {
            let vatAmount: any = rate * (vatPercent / 100);
            let c_p_vatAmount: any = cost_price * (vatPercent / 100);
            if (val.includevat || arr.includevat) {
                vatAmount = (rate / (100 + vatPercent)) * 100;
                c_p_vatAmount = (rate / (100 + vatPercent)) * 100;

                vatAmount = (rate - vatAmount).toFixed(2);
                c_p_vatAmount = (rate - vatAmount).toFixed(2);

                totalPrice = Number(rate.toFixed(2));
                cost_price_with_vat = Number(rate.toFixed(2));

                rate = rate - vatAmount;
                cost_price = rate - c_p_vatAmount;
            } else {
                totalPrice = Number(rate + vatAmount).toFixed(2);
                cost_price_with_vat = Number(rate + c_p_vatAmount).toFixed(2);
            }
            form.setFieldsValue({
                vatamt: Number(vatAmount).toFixed(2),
                cgst: Number(vatAmount) / 2,
                sgst: Number(vatAmount) / 2,
                total_price: Number(totalPrice).toFixed(2),
                cost_price_with_vat: Number(cost_price_with_vat).toFixed(2),
            });
        }

        if (val.product_category == 'category') {
            setSetingsModal(true)
            setInput({
                heading: 'Product Category',
                formtype: 'Product Category',
                url: API.PRODUCTCATEGORY_CREATE,
                inputnames: [
                    {
                        label: 'Category',
                        name: 'category',
                        inputtype: 'Input'
                    },
                    {
                        label: "Type",
                        name: "categoryType",
                        inputtype: "Select",
                        options:[{label:"Product",value:"product"},{label:"Service",value:"service"}]
                      },
                ]
            });
        }
        if (val.vat == 'vat') {
            setSetingsModal(true)
            setInput({
                heading: 'Vat',
                formtype: 'Vat',
                url: API.CREATE_TAX_MASTER,
                countryid: user?.companyInfo?.country,
                inputnames: [
                    {
                        label: 'Type',
                        name: 'type',
                        inputtype: 'Input',
                        initialValue: user?.companyInfo?.country == 101 ? "GST" : "VAT",
                    },
                    {
                        label: 'Percentage',
                        name: 'percentage_',
                        inputtype: 'InputNumber',
                        suffix: '%'
                    },
                ]
            });
        }
    };

    const refresh = () => {
        if (input.formtype == 'Product Category') {
            LoadCategory()
        } else if (input.formtype == 'Vat') {
            loadTaxList()
        }
    }
    return (
        <>
            <Modal
                open={productCModal}
                width={700}
                closable={true}
                footer={false}
                onCancel={() => {
                    form.resetFields();
                    close()
                }}
            >
                <div className="Table-Txt">
                    {id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.2")} {(t as any)("product.text_16")}
                </div>
                <div>{id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.3")} {(t as any)("product.text_10")} {id ? (t as any)("product.text_8.1") : (t as any)("product.text_8.2")} {(t as any)("product.text_17")}</div>
                <hr />
                <br />
                <Form
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                >
                    <Row>
                        <Col md={6}>
                            <label className="formLabel">{(t as any)("product.label_7")}</label>
                            <Form.Item
                                name="icode"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please enter a Service Code`,
                                    },
                                ]}
                            >
                                <Input size='large' />
                            </Form.Item>
                            <label className="formLabel">{(t as any)("product.label_23")}</label>
                            <Form.Item
                                name="idescription"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please enter a item name`,
                                    },
                                ]}
                            >
                                <Input size='large' />
                            </Form.Item>
                            <label className="formLabel">
                                {(t as any)("product.label_9")}
                            </label>
                            <Form.Item
                                name="product_category"
                                rules={[
                                {
                                    required: true,
                                    message: (t as any)("product.required_3"),
                                },
                                ]}
                            >
                                <Select size="large" allowClear>
                                <Select.Option value={"category"}>
                                    <b>{(t as any)("product.text_12")}</b>
                                </Select.Option>
                                {category?.length &&
                                    category.map((item: any) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.category}
                                    </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <label className="formLabel">{(t as any)("product.label_13")}</label>
                            <div className='image-box'>
                                <Upload
                                    // accept="image/*"
                                    style={{ color: "", padding: '0', width: '100%' }}
                                    listType="picture-card"
                                    showUploadList={false}
                                    onChange={onUpload}
                                    onPreview={onUpload}
                                >
                                    <br />
                                    <Spin
                                        spinning={loading}
                                        size="large"
                                        tip="Uploading..."
                                        indicator={
                                            <LoadingOutlined
                                                style={{ fontSize: 24, color: "#ff9800" }}
                                                spin
                                            />
                                        }
                                    >
                                        {image ? (
                                            <div>
                                                <img
                                                    src={image}
                                                />
                                            </div>
                                        ) : (
                                            <p>
                                                <MdOutlinePhotoCamera
                                                    size="20"
                                                    color="rgb(160 158 158)"
                                                />
                                                &nbsp;
                                                {(t as any)("product.text_15")}
                                                <br />
                                            </p>
                                        )}
                                    </Spin>
                                </Upload>
                            </div>
                        </Col>
                        <Col md={6}>
                            <label className="formLabel">{(t as any)("product.label_24")}</label>
                            <Form.Item
                                name="sale_price"
                                rules={[
                                    {
                                        required: true,
                                        message: (t as any)("product.required_6"),
                                    },
                                ]}
                            >
                                <Input
                                    onInput={(e: any) => {
                                        e.currentTarget.value = e?.currentTarget?.value?.replace(
                                            /[^0-9]/g,
                                            ""
                                        );
                                    }}
                                    size='large'
                                    type="number"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            {user?.companyInfo?.country == 101 ? (
                                <>
                                    <label className="formLabel">{(t as any)("product.label_15")}</label>
                                    <Form.Item
                                        name="hsn_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: (t as any)("product.required_7"),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            size='large'
                                            type="number"
                                            controls={false}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </>
                            ) : null}
                            <label className="formLabel">{user?.companyInfo?.country == 101 ? (t as any)("product.label_16") : (t as any)("product.label_17")} %</label>
                            <Form.Item
                                name="vat"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            user?.companyInfo?.country == 101
                                                ? (t as any)("product.required_8")
                                                : (t as any)("product.required_9"),
                                    },
                                ]}
                            >
                                <Select size='large' allowClear>
                                    <Select.Option
                                        value={'vat'}
                                    ><b>{(t as any)("product.text_8.2")} {user?.companyInfo?.country == 101 ? (t as any)("product.label_16") : (t as any)("product.label_17")}</b>
                                    </Select.Option>
                                    {taxlist?.length &&
                                        taxlist?.map((item: any) => (
                                            <Select.Option key={item?.percentage}>
                                                {`${item?.percentage} %`}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <label className="formLabel">{(t as any)("product.label_18")}</label>
                            <Form.Item
                                name="includevat"
                                style={{ border: 0, margin: 0 }}
                                valuePropName="checked"
                            >
                                <Checkbox
                                // onChange={(e) => {
                                //   form.setFieldsValue({
                                //     includevat: e.target.checked,
                                //   });
                                //   setIncludeVat(!includeVats);
                                // }}
                                // checked={includeVats}
                                />
                            </Form.Item>
                            <div style={{ marginBottom: '32px' }}></div>
                            <label className="formLabel">{(t as any)("product.label_19")}</label>
                            <Form.Item
                                name="vatamt"
                            >
                                <InputNumber
                                    disabled
                                    type="number"
                                    size='large'
                                    controls={false}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            <label className="formLabel">{(t as any)("product.label_20")}</label>
                            <Form.Item
                                name="total_price"
                            >
                                <InputNumber
                                    disabled
                                    type="number"
                                    size='large'
                                    controls={false}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            <Button
                                loading={isloading}
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                            >
                                {!id ? 'SUBMIT' : 'UPDATE'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal >
            {setingsModal ? (
                <Setings
                    open={setingsModal}
                    onCancel={() => {
                        let fealdName = input.heading == 'Vat' ? 'vat' : 'product_category'
                        form.setFieldValue(fealdName, null)
                        setSetingsModal(false)
                    }
                    }
                    formItem={input}
                    refresh={() => refresh()}
                    setFealdValue={(val: any) => {
                        let fealdName = input.heading == 'Vat' ? 'vat' : 'product_category'
                        form.setFieldValue(fealdName, val)
                    }}
                />
            ) : null}
        </>
    )
}

export default ServiceForm